import { FormConfig, Payload } from './AddEditRecord.type'
import { transactionService } from '../../services/transactions.service'
import { spudAPI } from '../../services/spud.service'
import { faListCheck, faMapLocation, faAddressBook } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export const SITE_FORM_ELEMENTS: Array<FormConfig> = [
  {
    title: 'Essential',
    ref: React.createRef(),
    icon: <FontAwesomeIcon icon={faListCheck as IconProp} />,
    fields: [
      {
        name: 'date_last_updated',
        label: 'Last Updated Date',
        type: 'hidden',
        defaultValue: '',
        rules: { required: false },
        serviceProviderEditable: false,
        allowAcceptServiceProviderChange: true,
      },
      {
        name: 'name',
        label: 'Site Name',
        type: 'text',
        rules: {
          required: {
            value: true,
            message: 'Please enter a site name',
          },
        },
        defaultValue: '',
        highlight: true,
        hideAsterisk: true,
        bulkUpdateEnabled: true,
        serviceProviderEditable: true,
        allowAcceptServiceProviderChange: true,
      },
      {
        name: 'also_known_as',
        label: 'Also Known As',
        type: 'customComponent',
        rules: {},
        defaultValue: [],
        customComponent: {
          name: 'SPUDAdvancedList',
          props: {
            title: 'Also Known As',
          },
        },
        serviceProviderEditable: false,
        allowAcceptServiceProviderChange: false,
      },
      {
        name: 'datasets',
        label: 'Dataset',
        type: 'multiselect',
        bulkAddEnabled: true,
        rules: {
          required: {
            value: true,
            message: 'Please select at least one database to which this site should export.',
          },
        },
        defaultValue: [],
        highlight: true,
        optionFunction: spudAPI.fetchDatasets,
        serviceProviderEditable: false,
        allowAcceptServiceProviderChange: false,
      },
      {
        name: 'organisation',
        label: 'Organisation',
        type: 'dropdown',
        rules: { required: true },
        defaultValue: null,
        canCreateNewRecord: true,
        createNewRecord: spudAPI.save,
        optionFunction: transactionService.recordList,
        asyncParams: { recordType: 'organisation' },
        highlight: true,
        exportLabel: 'organisation_id', // Must manually translate in spud-api
        serviceProviderEditable: false,
        allowAcceptServiceProviderChange: false,
      },
      {
        name: 'description',
        label: 'Site Description',
        type: 'textarea',
        rules: { required: true },
        defaultValue: '',
        highlight: true,
        bulkUpdateEnabled: true,
        serviceProviderEditable: true,
        allowAcceptServiceProviderChange: true,
        minHeight: '150px',
      },
      {
        name: 'provider_type',
        label: 'Provider Type',
        type: 'dropdown',
        rules: { required: true },
        options: [
          { id: 'Government', name: 'Government' },
          { id: 'Local Government', name: 'Local Government' },
          { id: 'State Government', name: 'State Government' },
          { id: 'Federal Government', name: 'Federal Government' },
          { id: 'Private', name: 'Private' },
          { id: 'Community', name: 'Community' },
        ],
        defaultValue: null,
        serviceProviderEditable: true,
        allowAcceptServiceProviderChange: true,
      },
      {
        name: 'is_indigenous_specific',
        label: 'Aboriginal (Indigenous) Specific',
        type: 'toggle',
        rules: {},
        defaultValue: false,
        serviceProviderEditable: false,
        allowAcceptServiceProviderChange: false,
      },
    ],
  },
  {
    title: 'Location',
    ref: React.createRef(),
    icon: <FontAwesomeIcon icon={faMapLocation as IconProp} />,
    fields: [
      {
        name: 'location',
        label: 'Location',
        type: 'customComponent',
        rules: {},
        highlight: true,
        defaultValue: {},
        customComponent: {
          name: 'SPUDLocation',
          props: {
            title: 'Site Address',
          },
        },
        serviceProviderEditable: true,
        allowAcceptServiceProviderChange: true,
      },
      {
        name: 'postal_addresses',
        label: 'Postal Address',
        type: 'customComponent',
        rules: {},
        defaultValue: [],
        customComponent: {
          name: 'SPUDPostalAddress',
          props: {
            title: 'Postal Address',
          },
        },
        serviceProviderEditable: true,
        allowAcceptServiceProviderChange: true,
      },
      {
        name: 'catchment',
        label: 'Geographic Coverage',
        type: 'customComponent',
        rules: {},
        highlight: true,
        defaultValue: [],
        customComponent: {
          name: 'SPUDGeographicCoverage',
          props: {
            title: 'Geographic Coverage',
            recordType: 'site',
          },
        },
        serviceProviderEditable: false,
        allowAcceptServiceProviderChange: false,
      },
      {
        name: 'parking_info',
        label: 'Parking Details',
        type: 'textarea',
        rules: {},
        defaultValue: '',
        serviceProviderEditable: true,
        allowAcceptServiceProviderChange: true,
      },
      {
        name: 'public_transport_info',
        label: 'Transport Details',
        type: 'textarea',
        rules: {},
        defaultValue: '',
        serviceProviderEditable: true,
        allowAcceptServiceProviderChange: true,
      },
      {
        name: 'accessibility',
        label: 'Accessibility Selection',
        type: 'multiselect',
        rules: {
          required: false,
          validate: (values) => {
            if (Array.isArray(values)) {
              const keys = values.map(key => key?.id)
              // Track how many of the standard
              // accessibility options are selected
              const standardAccessibilityList = []
              keys.forEach(key => {
                if (['fullaccess', 'access', 'noaccess'].includes(key)) {
                  standardAccessibilityList.push(key)
                }
              })
              // Raise an error if more than one is in the list
              if (standardAccessibilityList.length > 1) {
                return "More than one of the standard 'full access'," +
                  "'partial access' and 'no access' options is selected."
              }
            }
            // Return true regardless as it's not required
            return true
          },
        },
        options: [
          { id: 'fullaccess', name: 'Full wheelchair access' },
          { id: 'access', name: 'Partial wheelchair access' },
          { id: 'noaccess', name: 'No wheelchair access' },
          { id: 'accessible_toilet', name: 'Accessible toilet' },
          { id: 'full_sized_change_table_&_hoist', name: 'Full-sized change table & hoist' },
          { id: 'accessible_parking', name: 'Accessible parking' },
          { id: 'braille_&_tactile_signs', name: 'Braille & tactile signs' },
          { id: 'tactile_ground_surface_indicators', name: 'Tactile ground surface indicators' },
          { id: 'hearing_audio_loop_system', name: 'Hearing/audio loop system' },
          { id: 'ramp_level_entrance', name: 'Ramp/level entrance' },
          { id: 'elevators', name: 'Elevators' },
        ],
        defaultValue: [],
        serviceProviderEditable: true,
        allowAcceptServiceProviderChange: true,
      },
    ],
  }, {
    title: 'Contact',
    ref: React.createRef(),
    icon: <FontAwesomeIcon icon={faAddressBook as IconProp} />,
    fields: [
      {
        name: 'phones',
        label: 'Contact',
        type: 'customComponent',
        rules: {},
        defaultValue: [],
        highlight: true,
        customComponent: {
          name: 'SPUDContact',
          props: {
            title: 'Contact Information',
            recordType: 'site',
          },
        },
        serviceProviderEditable: true,
        allowAcceptServiceProviderChange: true,
      },
      {
        name: 'emails',
        label: 'Emails',
        type: 'customComponent',
        rules: {},
        defaultValue: [],
        customComponent: {
          name: 'SPUDEmails',
          props: {
            title: 'Emails',
            recordType: 'site',
          },
        },
        serviceProviderEditable: true,
        allowAcceptServiceProviderChange: true,
      },
      {
        name: 'bulk_update_email',
        label: 'Bulk update email',
        type: 'text',
        rules: {
          required: false,
          pattern: {
            // eslint-disable-next-line max-len
            value: /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
            message: 'That does not look like an email address, please try again.',
          },
        },
        hideAsterisk: true,
        defaultValue: '',
        allowAcceptServiceProviderChange: false,
        collapsable: true,
      },
      {
        name: 'web',
        label: 'Website',
        type: 'text',
        rules: {
          required: false,
          pattern: {
            value: /(www\.|http:\/\/|https:\/\/)(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/,
            message: 'That does not look like a valid web address, please try again.',
          },
        },
        hideAsterisk: true,
        defaultValue: '',
        serviceProviderEditable: true,
        allowAcceptServiceProviderChange: true,
      },
      {
        name: 'social_media',
        label: 'Socials',
        type: 'customComponent',
        rules: {},
        defaultValue: [],
        customComponent: {
          name: 'SPUDSocials',
          props: {
            title: 'Social Media',
          },
        },
        serviceProviderEditable: true,
        allowAcceptServiceProviderChange: true,
      },
      {
        name: 'opening_hours',
        label: 'Opening Hours',
        type: 'customComponent',
        rules: {},
        defaultValue: [],
        customComponent: {
          name: 'SPUDOpeningHours',
          props: {
            title: 'Opening Hours',
            recordType: 'site',
          },
        },
        allowAcceptServiceProviderChange: false,
      },
      {
        name: 'opening_hours_simple',
        label: 'Opening Hours',
        type: 'textarea',
        rules: {},
        defaultValue: '',
        serviceProviderEditable: true,
        serviceProviderFormOnly: true,
        allowAcceptServiceProviderChange: false,
      },
    ],
  },
]

export const SITE_FORM_DATA: Payload = {
  record_type: 'site',
  iss_id: null,
  status: 'draft',
  data: {},
}
