import React, { useEffect, useRef, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { OutsideComponentClick } from '../../../effects/OutsideComponentClick'
import { Alert, Button, Col, Row, Text, Title } from '@ix/ix-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faTimes, faPlus } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { SPUDInputField } from '../../../helpers/record'
import styled from 'styled-components'
import {
  openCloseType,
  isCloseTimeAfterOpenTime,
  TimeType,
} from './OpeningHours.service'
import { ControllerRenderProps } from 'react-hook-form/dist/types/controller'
import TimePicker from './TimePicker'
import { OpenHoursType } from './SPUDOpeningHours'

const OpenHoursDayPopperContainer = styled.div`
  padding: 1em;
  min-height: 100px;
  z-index: 2;
  width: 70%;
  position: absolute;
  box-shadow: 0 0 0 1px #eaeaea;
  background-color: #fff;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid black;
  bottom: 3em;
`

const BottomArrow = styled.div`
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid black;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 1.5em;
`

const OpeningHoursSummaryChipButton = styled.button`
  margin-left: 5;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1.3em;
  color: red;
  :hover {
    background: none;
    color: #e10606;
  }
  :focus {
    border: none;
  }
`

const StyledButton = styled(Button)`
  color: #3A8AE8;
  background: none;
  padding: 0 5px 0 0;
  font-size: 1.3em;
  :hover {
    color: #1458a8;
    background: none;
  }
  &:disabled {
    background: none;
  }
`

type EditOpeningHoursPopupProps = {
  indexes: number[],
  onClose: () => void,
  append: (value: {[x: string] : string}) => void,
  update: (index: number, value: {[x: string] : string}) => void,
  remove: (index: number) => void,
  day: string,
  disabled: boolean,
}

function EditOpeningHoursPopup (
  {
    indexes,
    onClose,
    append,
    update,
    day,
    remove,
    disabled,
  }: EditOpeningHoursPopupProps): React.ReactElement {
  const [comment, setComment] = useState('')
  const [invalidTimes, setInvalidTimes] = useState(false)
  const { control, trigger, getValues } = useFormContext()

  const defaultOpenTime = {
    hour: '09',
    minute: '00',
    amPm: 'AM',
  }

  const defaultCloseTime = {
    hour: '05',
    minute: '00',
    amPm: 'PM',
  }

  // Convert time in 24hrs to 12-hour format and AM/PM
  const convertTimeToAmPm = (time: string): TimeType | null => {
    const [hour, minute] = time.split(':').map(Number)
    const formattedHour = hour % 12 === 0 ? 12 : hour % 12
    const amPm = hour < 12 ? 'AM' : 'PM'
    return {
      hour: formattedHour.toString().padStart(2, '0'),
      minute: minute.toString().padStart(2, '0'),
      amPm: amPm,
    }
  }

  // Loop through all times for the current day and check if the time being set/edited doesn't overlap previous times
  const checkTimeOverlap = (start1: Date, end1: Date, openingHour: OpenHoursType) => {
    const start2 = new Date(`2022-01-01T${openingHour.open}`)
    const end2 = new Date(`2022-01-01T${openingHour.close}`)

    return start1 < end2 && end1 > start2
  }

  const isTimeOverlapping = (time: { open?: string; close?: string }, ignoreIndex: number): boolean => {
    for (const index of indexes) {
      const openValue = getValues(`opening_hours.${index}.open` as string)
      const closeValue = getValues(`opening_hours.${index}.close` as string)

      if (openValue && closeValue && time.open && time.close && index !== ignoreIndex) {
        if (openValue === '00:00' && closeValue === '00:00') {
          return true
        } else if (time.open === '00:00' && time.close === '00:00') {
          return true
        } else {
          const newStartTime = new Date(`2022-01-01T${time.open}`)
          const newEndTime = new Date(`2022-01-01T${time.close}`)
          const overlappingHours = checkTimeOverlap(newStartTime, newEndTime, getValues(`opening_hours.${index}`))

          if (overlappingHours) {
            return true
          }
        }
      }
    }
    return false
  }

  useEffect(() => {
    let currentComment = ''
    for (const index of indexes) {
      const savedComment = getValues(`opening_hours.${index}.note`)
      if (currentComment !== savedComment) {
        currentComment = ` ${savedComment}`
      }
      setComment(currentComment)
      const timeSlotAlreadyExists = isTimeOverlapping(
        {
          open: getValues(`opening_hours.${index}.open`),
          close: getValues(`opening_hours.${index}.close`),
        }, index)
      setInvalidTimes(timeSlotAlreadyExists)
    }
  }, [indexes?.length])

  const openingHoursRef = useRef(null)
  const clickedOutsideComponent = OutsideComponentClick(openingHoursRef)

  useEffect(() => {
    if (clickedOutsideComponent) {
      onClose()
    }
  }, [clickedOutsideComponent])

  const onTimeChange = (time: string, index: number, field :ControllerRenderProps, type: openCloseType) => {
    const isTimeValid = isCloseTimeAfterOpenTime(time, getValues(`opening_hours.${index}`), type)
    let timeSlotAlreadyExists = false
    if (isTimeValid) {
      if (type === 'open') {
        timeSlotAlreadyExists = isTimeOverlapping(
          {
            open: time,
            close: getValues(`opening_hours.${index}.close`),
          }, index)
      } else {
        timeSlotAlreadyExists = isTimeOverlapping(
          {
            close: time,
            open: getValues(`opening_hours.${index}.open`),
          }, index)
      }
    }
    setInvalidTimes(timeSlotAlreadyExists || !isTimeValid)
    field.onChange(time)
  }

  return (
    <>
      <OpenHoursDayPopperContainer ref={openingHoursRef}>
        {indexes.map((index, i) =>
          <Row key={index} justify='space-between' align='center' style={{ margin: '10px 0' }}>
            <Col flex={2}>
              {i === indexes.length - 1 && (
                <StyledButton
                  active={!invalidTimes}
                  style={{ width: '100%' }}
                  disabled={invalidTimes}
                  title="Add additional"
                  onClick={() => {
                    append({
                      open: '09:00',
                      close: '17:00',
                      note: comment,
                      day,
                    })
                  }}
                >
                  <FontAwesomeIcon icon={faPlus as IconProp} />
                </StyledButton>
              )}
            </Col>
            <Col flex={4} style={{ minWidth: '260px', padding: '0' }}>
              <Controller
                name={`opening_hours.${index}.open` as string}
                control={control}
                defaultValue={null}
                render={({ field }) =>
                  <TimePicker
                    defaultValue={convertTimeToAmPm(field.value) || defaultOpenTime}
                    onTimeSelect={(time: string) => {
                      onTimeChange(time, index, field, 'open')
                      trigger(`opening_hours.${index}.open`)
                    }}
                  />
                }
              />
            </Col>
            <Col align='center'>
              <FontAwesomeIcon icon={faArrowRight as IconProp} />
            </Col>
            <Col flex={4} style={{ minWidth: '245px', padding: '0', marginRight: '0' }}>
              <Controller
                name={`opening_hours.${index}.close` as string}
                control={control}
                defaultValue={null}
                render={({ field }) =>
                  <TimePicker
                    defaultValue={convertTimeToAmPm(field.value) || defaultCloseTime}
                    onTimeSelect={(time: string) => {
                      onTimeChange(time, index, field, 'close')
                      trigger(`opening_hours.${index}.close`)
                    }}
                  />
                }
              />
            </Col>
            <Col flex={1} style={{ marginLeft: '0' }}>
              {indexes.length > 1 && (
                <OpeningHoursSummaryChipButton
                  onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                    event.preventDefault()
                    remove(index)
                  }}
                  disabled={disabled}
                >
                  <FontAwesomeIcon icon={faTimes as IconProp} />
                </OpeningHoursSummaryChipButton>
              )}
            </Col>
          </Row>,
        )}
        <Row>
          <Col>
            {invalidTimes && <Alert type='error' style={{ marginTop: 0 }}>
              Open/close times are invalid. Check that the times don&apos;t overlap or close is before open
            </Alert>}
          </Col>
        </Row>
        <Row>
          <Col>
            <SPUDInputField
              label={<Title level={4} marginTop='5px'>Comment <Text>
                (if applicable)
              </Text></Title>}
              value={comment}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setComment(event.target.value)
                for (const index of indexes) {
                  const currentObj = getValues(`opening_hours.${index}`)
                  update(index, { ...currentObj, note: event.target.value })
                }
              }}
              fullwidth
            />
          </Col>
        </Row>
      </OpenHoursDayPopperContainer>
      <BottomArrow />
    </>
  )
}

export default EditOpeningHoursPopup
