import React from 'react'
import {
  Row,
  Col,
  Title,
} from '@ix/ix-ui'
import styled from 'styled-components'
import { formatDatetimeToLocal } from '../../helpers/datetime'

const BulkUpdateRecordDetailsContainer = styled.div`
  width: 100%;
  padding: 1em;
  margin: 1em 0;
  background-color: #fff;
`

type BulkUpdateRecordDetailsProps = {
  bulkUpdateRecord: {
    contact_name: string,
    contact_number: string,
    contact_email: string,
    date_submitted: string,
  },
}

function BulkUpdateRecordDetails ({ bulkUpdateRecord }: BulkUpdateRecordDetailsProps) {
  return <BulkUpdateRecordDetailsContainer>
    <Row>
      <Col>
        <Title level={3}>
          Service provider updates
        </Title>
        Date submitted: {formatDatetimeToLocal(bulkUpdateRecord.date_submitted)}
      </Col>
    </Row>
    <Row>
      <Col>
        <Title level={4}>Contact name: </Title>
        {bulkUpdateRecord.contact_name}
      </Col>
      <Col>
        <Title level={4}>Contact number: </Title>
        {bulkUpdateRecord.contact_number}
      </Col>
    </Row>
    <Row>
      <Col>
        <Title level={4}>Contact email:</Title>
        {bulkUpdateRecord.contact_email}
      </Col>
    </Row>
  </BulkUpdateRecordDetailsContainer>
}

export default BulkUpdateRecordDetails
