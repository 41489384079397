
import React, { useState } from 'react'
import { SPUDServiceRecordDetails } from '../../../@types/Service.type'
import { SPUDSiteRecordDetails } from '../../../@types/Site.type'
import { BulkUpdateContext } from './BulkUpdateContext'
import { BulkAutoUpdateRecord, ContactInfoType } from './BulkUpdateContext.type'
import { SPUDOrganisationRecordDetails } from '../../../@types/Organisation.type'
import { BulkUpdateFlowStateType } from './BulkAutoUpdateService'
import { DialogOptions } from '../../context/AppContext.type'

type Props = {
    children: React.ReactNode
}

function BulkUpdateContextProvider (props: Props): React.ReactElement {
  const [activeServiceIndex, setActiveServiceIndexContext] = useState<number | null>(null)
  const [organisation, setOrganisation] = useState<
    BulkAutoUpdateRecord<SPUDOrganisationRecordDetails> | Record<string, never>
  >({})
  const [site, setSiteData] = useState<BulkAutoUpdateRecord<SPUDSiteRecordDetails>>({
    recordType: 'site',
    confirmed: false,
    currentData: {},
    comment: '',
    noChanges: false,
    originalData: {},
  })
  const [services, setServiceData] = useState<Array<BulkAutoUpdateRecord<SPUDServiceRecordDetails>>>([])

  const [contactInfo, setContactInfo] = useState<ContactInfoType>({
    contact_name: '',
    contact_number: '',
    contact_email: '',
  })

  const [dialogOptions, setDialogOptions] = useState<DialogOptions>({
    show: false,
    title: '',
    description: '',
    type: 'confirm',
  })

  const bulkUpdateAutoSave = (
    dataKey: string,
    data: BulkAutoUpdateRecord<SPUDSiteRecordDetails> |
      Array<BulkAutoUpdateRecord<SPUDServiceRecordDetails>> |
      BulkAutoUpdateRecord<SPUDOrganisationRecordDetails> |
      ContactInfoType,
  ) => {
    localStorage.setItem(dataKey, JSON.stringify(data))
  }

  const bulkUpdateAutoLoadFromStorage = (
    dataKey: string,
  ): BulkAutoUpdateRecord<SPUDSiteRecordDetails> |
    Array<BulkAutoUpdateRecord<SPUDServiceRecordDetails>> |
    BulkAutoUpdateRecord<SPUDOrganisationRecordDetails> |
    ContactInfoType |
    null => {
    if (localStorage.getItem(dataKey)) {
      return JSON.parse(localStorage.getItem(dataKey) as string)
    }
    return null
  }

  const saveOrganisationData = (data: BulkAutoUpdateRecord<SPUDOrganisationRecordDetails>) => {
    const accessCode = sessionStorage.getItem('bulk_update_code')
    setOrganisation(data)
    bulkUpdateAutoSave(`${accessCode}_organisation`, data)
  }

  const saveSiteData = (data: BulkAutoUpdateRecord<SPUDSiteRecordDetails>) => {
    const accessCode = sessionStorage.getItem('bulk_update_code')
    setSiteData(data)
    bulkUpdateAutoSave(`${accessCode}_site`, data)
  }

  const saveServiceData = (data: Array<BulkAutoUpdateRecord<SPUDServiceRecordDetails>>) => {
    const accessCode = sessionStorage.getItem('bulk_update_code')
    setServiceData(data || [])
    bulkUpdateAutoSave(`${accessCode}_services`, data)
  }

  const saveContactInfo = (data: ContactInfoType) => {
    const accessCode = sessionStorage.getItem('bulk_update_code')
    setContactInfo(data || [])
    bulkUpdateAutoSave(`${accessCode}_contact_info`, data)
  }

  const loadData = (accessCode: string) => {
    setOrganisation(
      bulkUpdateAutoLoadFromStorage(
        `${accessCode}_organisation`) as BulkAutoUpdateRecord<SPUDOrganisationRecordDetails>,
    )
    setSiteData(
      bulkUpdateAutoLoadFromStorage(`${accessCode}_site`) as BulkAutoUpdateRecord<SPUDSiteRecordDetails>,
    )
    setServiceData(
      bulkUpdateAutoLoadFromStorage(
        `${accessCode}_services`) as Array<BulkAutoUpdateRecord<SPUDServiceRecordDetails>> || [],
    )

    setContactInfo(
      bulkUpdateAutoLoadFromStorage(`${accessCode}_contact_info`) as ContactInfoType || {
        contact_name: '',
        contact_number: '',
        contact_email: '',
      },
    )
  }

  const orgInStorage = (accessCode: string | undefined) => {
    const code = accessCode || sessionStorage.getItem('bulk_update_code')
    return !!localStorage.getItem(`${code}_organisation`)
  }

  const siteInStorage = (accessCode: string | undefined) => {
    const code = accessCode || sessionStorage.getItem('bulk_update_code')
    return !!localStorage.getItem(`${code}_site`)
  }

  const contactInfoInStorage = (accessCode: string | undefined) => {
    const code = accessCode || sessionStorage.getItem('bulk_update_code')
    return !!localStorage.getItem(`${code}_contact_info`)
  }

  const serviceInStorage = (accessCode: string | undefined) => {
    const code = accessCode || sessionStorage.getItem('bulk_update_code')
    return !!localStorage.getItem(`${code}_services`)
  }

  const clearState = () => {
    const accessCode = sessionStorage.getItem('bulk_update_code')
    localStorage.removeItem(`${accessCode}_flow_state`)
    localStorage.removeItem(`${accessCode}_organisation`)
    localStorage.removeItem(`${accessCode}_site`)
    localStorage.removeItem(`${accessCode}_services`)
    localStorage.removeItem(`${accessCode}_contact_info`)
    sessionStorage.removeItem('bulk_update_code')
  }

  const setFlowState = (flowState: BulkUpdateFlowStateType) => {
    const code = sessionStorage.getItem('bulk_update_code')
    localStorage.setItem(`${code}_flow_state`, flowState)
  }

  const loadFlowState = (): BulkUpdateFlowStateType | null => {
    const code = sessionStorage.getItem('bulk_update_code')
    return localStorage.getItem(`${code}_flow_state`) as BulkUpdateFlowStateType
  }

  return (
    <BulkUpdateContext.Provider
      value={{
        organisation: organisation,
        site: site,
        contactInfo: contactInfo,
        services: services,
        activeServiceIndexContext: activeServiceIndex,
        saveOrganisationData,
        saveSiteData,
        saveServiceData,
        saveContactInfo,
        loadData,
        setActiveServiceIndexContext,
        clearState,
        orgInStorage,
        siteInStorage,
        contactInfoInStorage,
        serviceInStorage,
        setFlowState,
        loadFlowState,
        dialogOptions,
        setDialogOptions,
      }}
    >
      {props.children}
    </BulkUpdateContext.Provider>
  )
}

export default BulkUpdateContextProvider
