import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Row } from '@ix/ix-ui'
import { BulkServiceProviderUpdateType } from '../../services/spud.service'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faInfo, faUndo } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  bulkUpdateRecordChangeReviewType,
  FieldArray,
  flattenArray,
  flattenLocationObj, flattenNewLocationObj,
  flattenOpeningHoursObj,
  markDifferences,
} from '../../externalPages/BulkAutoUpdate/BulkAutoUpdateService'
import * as _ from 'lodash'
import { OpenHoursType } from '../General/OpeningHours/SPUDOpeningHours'
import { format } from 'date-fns'
import { OutsideComponentClick } from '../../effects/OutsideComponentClick'
import BulkUpdateRecordChangeContentPopup from '../BulkUpdateRecordChangeContentPopup'

const BulkUpdateRecordChangeReviewContainer = styled.div`
  padding: 0.5em;
  margin: 5px 0;
  background-color: #f3f3f3;
  border-radius: 3px;
`
const BulkUpdateRecordChangeReviewRow = styled(Row)`
  padding: 10px;
  margin: 10px 0;
  border: 2px solid #478FE5;
  background-color: #E3EDFA;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const BulkUpdateContactInfoButton = styled(Button)`
  padding: 0;
  margin: 0;
  border: 1px solid black;
  height: 2em;
  width: 2em;
  border-radius: 50%;
`

const BulkUpdateRecordChangeReviewButton = styled(Button)`
  background: none;
  font-size: 1em;
  color: ${props => props.theme.colors.primary};
  :hover {
    background: none;
    font-size: 1.2em;
    color: ${props => props.theme.colors.primary};
  }
`

type BulkUpdateRecordChangeReviewProps = {
  bulkUpdateChanges: BulkServiceProviderUpdateType,
  fieldKey: string,
  fieldLabel: string,
  setValue?: (key: string, value: unknown, newChanges?: boolean) => void
  currentFieldValue: bulkUpdateRecordChangeReviewType
  currentOpeningHours?: Array<OpenHoursType>
  allowChange?: boolean
}

function BulkUpdateRecordChangeReview (
  {
    bulkUpdateChanges,
    fieldKey,
    fieldLabel,
    setValue,
    currentFieldValue,
    currentOpeningHours,
    allowChange = true,
  }: BulkUpdateRecordChangeReviewProps) {
  const [currentValue, setCurrentValue] = useState<bulkUpdateRecordChangeReviewType>()
  const [valueChanged, setValueChanged] = useState<{[x: string]: boolean}>({})
  const [bulkUpdateContactPopup, setBulkUpdateContactPopup] = useState<{[x: string]: boolean}>({})
  const [contextRefs, setContextRefs] = useState<
    {[x: string]: { refs: {setReference: unknown}, refPropsFunc:() => {[x:string]: unknown}}}>({})
  const [currentRow, setCurrentRow] = useState<string>('')

  const BulkUpdateRecordChangeReviewRef = useRef(null)
  const clickedOutsideComponent = OutsideComponentClick(BulkUpdateRecordChangeReviewRef)

  const closeAllPopups = () => {
    const closePopups: {[x: string]: boolean} = {}
    Object.keys(bulkUpdateContactPopup).forEach((key) => {
      closePopups[key] = false
    })
    setBulkUpdateContactPopup(closePopups)
  }

  useEffect(() => {
    if (clickedOutsideComponent) {
      closeAllPopups()
    }
  }, [clickedOutsideComponent])

  useEffect(() => {
    if (fieldKey === 'opening_hours_simple' && currentOpeningHours) {
      setCurrentValue(flattenOpeningHoursObj(currentOpeningHours) as bulkUpdateRecordChangeReviewType)
    } else if (Array.isArray(currentFieldValue) && currentFieldValue.length) {
      !currentValue?.length && setCurrentValue(currentFieldValue)
    } else if (fieldKey === 'location' && currentFieldValue?.state) {
      !currentValue?.state && setCurrentValue(currentFieldValue)
    } else {
      !currentValue && setCurrentValue(currentFieldValue)
    }
  }, [currentFieldValue])

  useEffect(() => {
    let defaultVals = {}
    bulkUpdateChanges?.update_data?.forEach((test, index) => {
      if (test?.data[fieldKey]) {
        defaultVals = {
          ...defaultVals,
          [`${fieldKey}_${index}`]: true,
        }
      }
    })
  }, [])

  const hasDifference = (value: unknown) => {
    if (['location', 'postal_addresses', 'emails', 'social_media', 'phones', 'accreditation'].includes(fieldKey)) {
      return true
    }
    if (Array.isArray(value)) {
      const flattenArrayObj = flattenArray(
        value as FieldArray, currentValue as FieldArray || [], fieldKey, false)
      return flattenArrayObj.hasDifference
    }
    const splitOriginalValues = currentValue as string
    const splitNewValues = value as string
    const difference = splitOriginalValues
      ? _.difference(splitNewValues.split(' '), splitOriginalValues.split(' '))
      : splitNewValues.split(' ')
    return difference.length > 0
  }

  const renderChangeValue = (newValue: unknown) => {
    let splitOriginalValues = currentValue as string
    let splitNewValues = newValue as string
    if (fieldKey === 'location' && currentValue) {
      splitNewValues = flattenLocationObj(newValue as {
        building_name: string,
            confidential: boolean,
            flat_unit: string,
            floor_level: string,
            postcode: string,
            state: string,
            street_number: string,
            street_name: string,
            street_suffix: string,
            street_type: string,
            suburb: string,
            geo_point: {
              lat: string,
              lon: string
            },
          })
      splitOriginalValues = flattenLocationObj(currentValue)
    } else if (Array.isArray(newValue)) {
      const flattenArrayObj = flattenArray(
        newValue as FieldArray, currentValue as FieldArray || [], fieldKey, false)
      return flattenArrayObj
    }
    if (typeof splitNewValues !== 'string') {
      return null
    }
    const difference = splitOriginalValues
      ? _.difference(splitNewValues.split(' '), splitOriginalValues.split(' '))
      : splitNewValues.split(' ')
    if (!difference.length) {
      // In the event that there is no Current Value,
      // but there is a new value for a location we need to flatten it
      if (typeof newValue === 'object' && fieldKey === 'location') {
        const flattenedLocation = flattenNewLocationObj(newValue as {
        building_name: string,
            confidential: boolean,
            flat_unit: string,
            floor_level: string,
            postcode: string,
            state: string,
            street_number: string,
            street_name: string,
            street_suffix: string,
            street_type: string,
            suburb: string,
            geo_point: {
              lat: string,
              lon: string
            },
          },
          splitOriginalValues as unknown as {
            building_name: string,
            confidential: boolean,
            flat_unit: string,
            floor_level: string,
            postcode: string,
            state: string,
            street_number: string,
            street_name: string,
            street_suffix: string,
            street_type: string,
            suburb: string,
            geo_point: {
              lat: string,
              lon: string
            },
          })
        return <span>{flattenedLocation.map((word, index) => (
          <span key={index}>{word} </span>
        ))}</span>
      }
      return newValue
    }
    return markDifferences(
      fieldKey === 'web' && splitOriginalValues
        ? splitOriginalValues.split('')
        : splitOriginalValues
          ? splitOriginalValues.split(' ')
          : [''],
      fieldKey === 'web'
        ? splitNewValues.split('')
        : splitNewValues.split(' '),
      fieldKey === 'web')
  }

  const formatDateSubmitted = (date: string) => {
    return format(new Date(date), 'dd/MM/yyyy hh:mm aa')
  }

  const UpdateContainsField = () => {
    return bulkUpdateChanges.update_data.find(update => !!update.data?.[fieldKey])
  }

  const closeOtherPopups = (key: string) => {
    if (currentRow && key !== currentRow && bulkUpdateContactPopup[currentRow]) {
      closeAllPopups()
    }
    setCurrentRow(key)
  }

  const returnChanges = (
    externalUpdate: {
      data: {
        [x: string]: unknown
      },
      author: {
        contact_name: string,
        contact_number: string,
        contact_email: string,
      },
      date_submitted: string
    },
    fieldKey: string,
  ) => {
    const formattedChanges = renderChangeValue(
      externalUpdate.data?.[fieldKey]) as string | Array<string | React.ReactElement> | {element?: React.ReactElement}
    if (typeof formattedChanges === 'string') {
      return <div dangerouslySetInnerHTML={{ __html: formattedChanges }}/>
    } else if (Array.isArray(formattedChanges)) {
      return formattedChanges.map((word, index) => (
        <span key={index}>{word} </span>
      ))
    } else if (formattedChanges && 'element' in formattedChanges && formattedChanges.element) {
      return formattedChanges.element
    } else {
      return null
    }
  }

  if (bulkUpdateChanges?.update_data?.length) {
    return UpdateContainsField()
      ? <BulkUpdateRecordChangeReviewContainer ref={BulkUpdateRecordChangeReviewRef}>
        <>
          {bulkUpdateChanges?.update_data?.map((externalUpdate, index) =>
            externalUpdate.data?.[fieldKey] &&
            <BulkUpdateRecordChangeReviewRow
              align='center'
              key={`${fieldKey}_${index}`}
              ref={contextRefs[`${fieldKey}_${index}_contact`]?.refs?.setReference}
              {...contextRefs[`${fieldKey}_${index}_contact`]?.refPropsFunc()}
            >
              <Col direction='row' justify='flex-start'>
                <Row>
                  <Col>
                    <Row>
                      <strong style={{ textTransform: 'capitalize' }}>{fieldLabel} (Update {index + 1}):</strong>
                      <div style={{ margin: '0 0.5em' }}>
                        <>
                          {returnChanges(externalUpdate, fieldKey)}
                        </>
                      </div>
                    </Row>
                    {externalUpdate.author.contact_name && <Row>
                      Updated by - {externalUpdate.author.contact_name} | {
                        formatDateSubmitted(externalUpdate.date_submitted)
                      }
                      <div
                        style={{ marginLeft: '1em' }}
                        aria-label={`${fieldKey}_${index}_contact`}
                      >
                        <BulkUpdateContactInfoButton
                          onClick={() => {
                            setBulkUpdateContactPopup({
                              ...bulkUpdateContactPopup,
                              [`${fieldKey}_${index}_contact`]: true,
                            })
                          }}
                        >
                          <FontAwesomeIcon icon={faInfo as IconProp} />
                        </BulkUpdateContactInfoButton>
                        <BulkUpdateRecordChangeContentPopup
                          author={externalUpdate.author}
                          isOpen={bulkUpdateContactPopup?.[`${fieldKey}_${index}_contact`]}
                          setIsOpen={() => {
                            if (bulkUpdateContactPopup?.[`${fieldKey}_${index}_contact`]) {
                              setBulkUpdateContactPopup({
                                ...bulkUpdateContactPopup,
                                [`${fieldKey}_${index}_contact`]: false,
                              })
                            } else {
                              closeOtherPopups(`${fieldKey}_${index}_contact`)
                            }
                          }}
                          parentRefs={(refs, refPropsFun) => {
                            if (!Object.hasOwn(contextRefs, `${fieldKey}_${index}_contact`)) {
                              setContextRefs({
                                ...contextRefs,
                                [`${fieldKey}_${index}_contact`]: {
                                  refs: {
                                    setReference: refs.setReference,
                                  },
                                  refPropsFunc: refPropsFun,
                                },
                              })
                            }
                          }}
                        />
                      </div>
                    </Row>}
                  </Col>
                </Row>
              </Col>
              {allowChange && hasDifference(externalUpdate.data?.[fieldKey]) && <Col direction='row' justify='flex-end'>
                {!valueChanged[`${fieldKey}_${index}`] &&
                  <BulkUpdateRecordChangeReviewButton
                    onClick={() => {
                      setValue?.(fieldKey, externalUpdate.data?.[fieldKey], true)
                      setValueChanged({
                        ...valueChanged,
                        [`${fieldKey}_${index}`]: true,
                      })
                    }}
                    title='accept changes'
                  >
                    <FontAwesomeIcon icon={faCheck as IconProp} />
                  </BulkUpdateRecordChangeReviewButton>}
                {valueChanged[`${fieldKey}_${index}`] &&
                  <BulkUpdateRecordChangeReviewButton
                    onClick={() => {
                      setValue?.(fieldKey, currentValue)
                      setCurrentValue(currentValue)
                      setValueChanged({
                        ...valueChanged,
                        [`${fieldKey}_${index}`]: false,
                      })
                    }}
                    title='undo changes'
                  >
                    <FontAwesomeIcon icon={faUndo as IconProp} />
                  </BulkUpdateRecordChangeReviewButton>}
              </Col>}
              {
                allowChange && !hasDifference(externalUpdate.data?.[fieldKey]) &&
                <Col direction='row' justify='flex-end'>
                  No Difference
                </Col>
              }
            </BulkUpdateRecordChangeReviewRow>,
          )}
        </>
      </BulkUpdateRecordChangeReviewContainer>
      : null
  }
  return null
}

export default BulkUpdateRecordChangeReview
