import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Col, Row, Title, Button } from '@ix/ix-ui'
import BulkAutoUpdateForm from './BulkAutoUpdateForm'
import { BulkUpdateContextType, BulkUpdateSPUDRecordType } from './BulkUpdateContext.type'
import { appendNewFieldsToOriginalForProcessing, BulkUpdateFlowStateType } from './BulkAutoUpdateService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimes } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { SPUDServiceRecordDetails } from '../../../@types/Service.type'

const SPUDServiceForm = styled.div`
  width: 70%;
`

const ServiceLeftPanel = styled.div`
  display: table-cell;
  padding: 0;
  margin-left: 15px;
  background-color: #515257;
`

const ServiceList = styled.ul`
  padding: 0;
`

const FormContainer = styled.div`
  width: 100%;
  margin-left: 0;
  padding: 1em;
  background-color: #fff;
`

const ServiceListItem = styled.li<{active: boolean}>`
  display: block;
  padding: 10px;
  border: 0;
  font-family: inherit;
  font-size: 14px;
  text-align: left;
  color: #252223;
  background-color: ${props => props.active ? '#fff' : '#d1d2d4'};
  margin-bottom: 10px;
  margin-right: ${props => props.active ? '0' : '1em'};
  margin-left: 1em;
  position: relative;
  :hover {
    cursor: pointer;
  }
`

type ServiceFormProps = {
  recordId: number,
  setBulkAutoUpdateFlowState: (flowState: BulkUpdateFlowStateType) => void
} & BulkUpdateContextType

function ServiceForm (
  {
    setBulkAutoUpdateFlowState,
    saveServiceData,
    services,
    setActiveServiceIndexContext,
    activeServiceIndexContext,
    site,
    setDialogOptions,
  }: ServiceFormProps) {
  const [activeServiceIndex, setActiveServiceIndex] = useState(0)
  const [newServices, setNewService] = useState<{[x: number]: boolean}>({})
  const addNewService = () => {
    const nextIndex = services.length || 0
    saveServiceData([
      ...services,
      {
        recordType: 'service',
        currentData: {},
        originalData: {},
        comment: '',
        confirmed: false,
        noChanges: false,
      },
    ])
    setNewService({
      ...newServices,
      [nextIndex]: true,
    })
    setActiveServiceIndex(nextIndex)
    setActiveServiceIndexContext(nextIndex)
  }

  useEffect(() => {
    activeServiceIndexContext !== null && setActiveServiceIndex(activeServiceIndexContext)
  }, [activeServiceIndexContext])

  const deleteNewService = (index: number) => {
    setActiveServiceIndex(0)
    setActiveServiceIndexContext(0)
    const newServiceList = services
    newServiceList.splice(index, 1)
    saveServiceData(newServiceList)
    services.length === 0 && setNewService({})
  }

  return <SPUDServiceForm>
    <Row style={{ padding: '1em' }}>
      <Col>
        <Title>
          Update service
        </Title>
      </Col>
    </Row>
    <Row style={{ padding: '1em' }}>
      <Col>
        <hr style={{ width: '100%' }}/>
      </Col>
    </Row>
    <Row>
      <Col style={{ padding: 0 }}>
        <ServiceLeftPanel>
          <ServiceList>
            {services.map((service, index) =>
              <ServiceListItem
                key={index}
                active={activeServiceIndex === index}
                onClick={() => {
                  if (services?.[activeServiceIndex]) {
                    if (activeServiceIndex !== index) {
                      services[activeServiceIndex] = {
                        recordType: 'service',
                        currentData: services[activeServiceIndex]?.currentData,
                        originalData: appendNewFieldsToOriginalForProcessing(
                          services[activeServiceIndex],
                        ) as Record<string, never> | BulkUpdateSPUDRecordType<SPUDServiceRecordDetails>,
                        comment: services[activeServiceIndex].comment,
                        confirmed: services[activeServiceIndex].confirmed,
                        noChanges: services[activeServiceIndex].noChanges,
                      }
                      saveServiceData(services)
                    }
                    setActiveServiceIndex(index)
                    setActiveServiceIndexContext(index)
                  }
                }}
              >
                <Row>
                  <Col direction='row' align='center' justify='space-between'>
                    {service.currentData.update?.data?.name || 'New Service'}{' '}
                    {(service.confirmed || service.noChanges) &&
                      <FontAwesomeIcon
                        icon={faCheckCircle as IconProp}
                        color='green'
                      />}
                    {!service.currentData.update?.data?.name &&
                      <Button
                        onClick={(event: React.ChangeEvent) => {
                          event.preventDefault()
                          deleteNewService(index)
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faTimes as IconProp}
                          color='red'
                        />
                      </Button>}
                  </Col>
                </Row>
              </ServiceListItem>,
            )}
            <Button onClick={() => addNewService()}>
              Add new service
            </Button>
          </ServiceList>
        </ServiceLeftPanel>
      </Col>
      <Col flex={4}>
        <FormContainer>
          {activeServiceIndex >= 0 && services.length > 0 && services?.[activeServiceIndex] &&
            <>
              <BulkAutoUpdateForm
                recordId={activeServiceIndex.toString()}
                recordType='service'
                formData={services[activeServiceIndex].currentData.update?.data}
                siteData={services[activeServiceIndex].currentData.site}
                originalData={services[activeServiceIndex].originalData.update?.data}
                contextUpdater={(data: SPUDServiceRecordDetails, comment: string, confirm: boolean, noChanges) => {
                  let currentData = services[activeServiceIndex].currentData
                  let originalData = services[activeServiceIndex].originalData
                  if (newServices[activeServiceIndex] && !currentData.update?.data?.site) {
                    data = {
                      ...data,
                      datasets: site.currentData.update?.data.datasets,
                      site: site.currentData.id,
                      is_active: true,
                    }
                    originalData = {
                      update: {
                        data: data,
                      },
                    } as BulkUpdateSPUDRecordType<SPUDServiceRecordDetails>
                  }
                  currentData = {
                    ...currentData,
                    update: {
                      ...services[activeServiceIndex].currentData.update,
                      data: {
                        ...services[activeServiceIndex].currentData?.update?.data || {},
                        ...data,
                      },
                    },
                  } as BulkUpdateSPUDRecordType<SPUDServiceRecordDetails>
                  services[activeServiceIndex] = {
                    recordType: 'service',
                    currentData: currentData,
                    originalData: originalData,
                    comment: comment,
                    confirmed: confirm,
                    noChanges: noChanges,
                  }
                  saveServiceData(services)
                }}
                confirmed={services[activeServiceIndex].confirmed}
                noChanges={services[activeServiceIndex].noChanges}
                comment={services[activeServiceIndex].comment}
                activeServiceIndexContext={activeServiceIndexContext}
                onNext={() => {
                  services[activeServiceIndex] = {
                    recordType: 'service',
                    currentData: services[activeServiceIndex].currentData,
                    originalData: services[activeServiceIndex].originalData,
                    comment: services[activeServiceIndex].comment,
                    confirmed: services[activeServiceIndex].confirmed,
                    noChanges: services[activeServiceIndex].noChanges,
                  }
                  saveServiceData(services)
                  const nextIndex = activeServiceIndex + 1
                  setActiveServiceIndex(nextIndex)
                  if (nextIndex === services.length) {
                    setActiveServiceIndexContext(null)
                    window.scrollTo({
                      top: 0,
                    })
                    setBulkAutoUpdateFlowState('review')
                  } else {
                    services[nextIndex] = {
                      recordType: 'service',
                      currentData: services[nextIndex].currentData,
                      originalData: services[nextIndex].originalData,
                      comment: services[nextIndex].comment,
                      confirmed: services[nextIndex].confirmed,
                      noChanges: services[nextIndex].noChanges,
                    }
                    saveServiceData(services)
                    window.scrollTo({
                      top: 0,
                    })
                  }
                }}
                onPrevious={() => {
                  services[activeServiceIndex] = {
                    recordType: 'service',
                    currentData: services[activeServiceIndex].currentData,
                    originalData: services[activeServiceIndex].originalData,
                    comment: services[activeServiceIndex].comment,
                    confirmed: services[activeServiceIndex].confirmed,
                    noChanges: services[activeServiceIndex].noChanges,
                  }
                  setActiveServiceIndexContext(null)
                  saveServiceData(services)
                  setBulkAutoUpdateFlowState('site')
                }}
                isNewService={
                  (newServices[activeServiceIndex] || services[activeServiceIndex]) &&
                  !services[activeServiceIndex].currentData?.id}
                setDialogOptions={setDialogOptions}
              />
            </>
          }
          {services.length === 0 && <Row>
            <Col direction='row' justify='space-between'>
              <Title>
                Nothing to update, click next to proceed to the review and submit.
              </Title>
              <Button active onClick={() => setBulkAutoUpdateFlowState('review')}>
                Next
              </Button>
            </Col>
          </Row>}
        </FormContainer>
      </Col>
    </Row>
  </SPUDServiceForm>
}

export default ServiceForm
