import { SITE_FILTER_OPTIONS } from './filters/site.filters'
import { ORGANISATION_FILTER_OPTIONS } from './filters/organisation.filters'
import { SERVICE_FILTER_OPTIONS } from './filters/service.filters'
import { EMAIL_CAMPAIGN_FILTER_OPTIONS } from './filters/email_campaign.filters'
import { getFormConfig, SPUDInputField, SPUDToggle } from '../../helpers/record'
import { Alert, Title } from '@ix/ix-ui'
import React from 'react'
import SPUDAutoComplete from '../../components/General/SPUDAutoComplete'
import { SPUDRecord } from '../../../@types/SPUDRecord.type'
import { SPUDSiteRecordDetails } from '../../../@types/Site.type'
import { SPUDOrganisationRecordDetails } from '../../../@types/Organisation.type'
import { SPUDServiceRecordDetails } from '../../../@types/Service.type'
import { userService, UserType } from '../../services/user.service'
import { Option } from '../../components/forms/AddEditRecord.type'
import TextInputWithSearchOperators from './CustomFilterComponents/TextInputWithSearchOperators'
import LocationFilter, { LocationFilterProps } from './CustomFilterComponents/LocationFilter'
import DateFilter, { DateFilterProps } from './CustomFilterComponents/DateFilter'
import { useAuth } from '../../helpers/auth'
import { transactionParams } from '../../services/transactions.service'
import { AxiosResponse } from 'axios'

export type QuickFilterPresetType = {
  name: string,
  label: string,
  position: 'advanced-panel' | 'above-list'
  count?: number,
  recordType?: string,
  default_order: string
  group?: 'status' | 'allocation'
}

export type SearchOperators = 'startswith' | 'contains' | 'endswith' | 'equals' | 'notequals' | 'doesnotcontain'
export type DateSearchOperators = 'before' | 'after' | 'equals' | 'range'

export const SEARCH_OPERATOR_MAP = {
  startswith: 'Starts with',
  contains: 'Contains',
  doesnotcontain: 'Does not contain',
  endswith: 'Ends with',
  equals: 'Is',
  notequals: 'Is not',
}

export const DATE_OPERATOR_MAP = {
  before: 'Before',
  after: 'After',
  equals: 'Equals',
  range: 'Range',
}

export type LocationFilterValueType = {
  suburb: Array<Option>
  suburbText: {
    searchOperator: SearchOperators,
    value: string
  },
  state: Option,
  postcode: string
}

export type DateFilterValueType = {
  date: string | null,
  fromDate?: string | null,
  dateSearchOperator: DateSearchOperators,
}

export type FilterValueType = string | boolean | Array<Option> | null | Option | Record<never, never>

export type AdvancedFilterType<T = FilterValueType> = {
  name: string,
  label: string,
  type: string,
  defaultValue: T,
  value: T,
  optionFunction?: ((params: transactionParams) => Promise<{
    data: {
      results: Array<UserType> | Array<SPUDRecord<
          SPUDSiteRecordDetails | SPUDOrganisationRecordDetails | SPUDServiceRecordDetails
        >> | Array<{ name: string}>,
      next: string | null,
    }
  }>),
  asyncParams?: {
    recordType: string,
  },
  formatDisplayName?: <T extends Record<string, string>>(value: Option & T) => string,
  formatValueName?: (value: Option) => string | number | null,
  additionalControls?: Array<SearchOperators>
  searchOperator?: SearchOperators,
  options?: Array<Option>,
  customComponent?: {
    name: string,
    props: {
      recordType: string,
      filterFieldProperties: {[x: string]: AdvancedFilterType<T> | unknown}
    }
  },
  requiredFields?: Array<string>
  default_order?: string
  role: 'any'|'Updater'|'Editor'|'Administrator'
  validation?: (value: unknown) => boolean
  errorMessage?: string
}

export type FilterPageType = 'dashboard'|'record-list'|'email-campaign'

const DEFAULT_QUICK_FILTERS: Array<QuickFilterPresetType> = [
  {
    name: 'deleted',
    label: 'Deleted',
    position: 'advanced-panel',
    default_order: 'last_updated_date',
    group: 'status',
  },
]

const UPDATER_QUICK_FILTERS: Array<QuickFilterPresetType> = [
  ...DEFAULT_QUICK_FILTERS,
  {
    name: 'due_for_update',
    label: 'Due for update',
    position: 'above-list',
    default_order: 'last_updated_date',
    group: 'status',
  },
  {
    name: 'in_review',
    label: 'In review',
    position: 'advanced-panel',
    default_order: 'last_updated_date',
    group: 'status',
  },
  {
    name: 'completed',
    label: 'Completed',
    position: 'advanced-panel',
    default_order: 'last_updated_date',
    group: 'status',
  },
]

const UPDATER_SITE_QUICK_FILTERS: Array<QuickFilterPresetType> = [
  ...DEFAULT_QUICK_FILTERS,
  {
    name: 'due_for_update',
    label: 'Due for update',
    position: 'above-list',
    default_order: 'last_updated_date',
    group: 'status',
  },
  {
    name: 'in_review',
    label: 'In review',
    position: 'advanced-panel',
    default_order: 'last_updated_date',
    group: 'status',
  },
  {
    name: 'allocated',
    label: 'Allocated to me',
    position: 'above-list',
    default_order: 'last_updated_date',
    group: 'allocation',
  },
  {
    name: 'completed',
    label: 'Completed',
    position: 'advanced-panel',
    default_order: 'last_updated_date',
    group: 'status',
  },
]

const UPDATER_DASHBOARD_QUICK_FILTERS: Array<QuickFilterPresetType> = [
  ...DEFAULT_QUICK_FILTERS,
  {
    name: 'due_for_update',
    label: 'Due for update',
    position: 'above-list',
    default_order: 'last_updated_date',
    group: 'status',
  },
  {
    name: 'site.status.draft',
    label: 'Draft',
    position: 'above-list',
    default_order: 'last_updated_date',
    group: 'status',
  },
  {
    name: 'in_review',
    label: 'In review',
    position: 'above-list',
    default_order: 'last_updated_date',
    group: 'status',
  },
  {
    name: 'allocated',
    label: 'Allocated to me',
    position: 'above-list',
    default_order: 'last_updated_date',
    group: 'allocation',
  },
]

const ADMIN_DASHBOARD_QUICK_FILTERS: Array<QuickFilterPresetType> = [
  ...DEFAULT_QUICK_FILTERS,
  {
    name: 'site.status.pending',
    label: 'Pending',
    position: 'above-list',
    default_order: 'last_updated_date',
    group: 'status',
  },
]

const ADMIN_QUICK_FILTERS: Array<QuickFilterPresetType> = [
  ...DEFAULT_QUICK_FILTERS,
  { name: 'unallocated', label: 'Unallocated', position: 'advanced-panel', default_order: 'last_updated_date' },
]

export const fetchCustomFilter = (
  customComponent: string | undefined | null,
  props: unknown,
): React.ReactElement => {
  switch (customComponent) {
  case 'LocationFilter':
    return LocationFilter(props as LocationFilterProps)
  case 'DateFilter':
    return DateFilter(props as DateFilterProps)
  default:
    return <span>Custom component not found</span>
  }
}

export const fetchQuickFilterPresetByRole = (
  userRole: 'Updater'|'Editor'|'Administrator',
  pageType: FilterPageType,
  recordType?: string,
): Array<QuickFilterPresetType> => {
  switch (userRole) {
  case 'Updater':
  case 'Editor':
    return pageType === 'dashboard'
      ? UPDATER_DASHBOARD_QUICK_FILTERS
      : (
        recordType && recordType === 'site'
          ? UPDATER_SITE_QUICK_FILTERS
          : UPDATER_QUICK_FILTERS
      )
  case 'Administrator':
    return pageType === 'dashboard' ? ADMIN_DASHBOARD_QUICK_FILTERS : ADMIN_QUICK_FILTERS
  }
}

export const quickFilterPresetToObj = (
  quickFilterPresets: Array<QuickFilterPresetType>,
): {[x: string]: AdvancedFilterType} => {
  let preset = {}
  quickFilterPresets.forEach(quickFilterPreset => {
    preset = { ...preset, label: quickFilterPreset.label, name: quickFilterPreset.name }
  })
  return preset
}

export const getFilterFields = (recordType: string) => {
  switch (recordType) {
  case 'site':
    return SITE_FILTER_OPTIONS
  case 'organisation':
    return ORGANISATION_FILTER_OPTIONS
  case 'service':
    return SERVICE_FILTER_OPTIONS
  case 'email-campaign':
    return EMAIL_CAMPAIGN_FILTER_OPTIONS
  default:
    return SITE_FILTER_OPTIONS
  }
}

export const fetchAdvancedFilterPresets = (
  pageType: FilterPageType,
  recordType: 'site'|'service'|'organisation',
): Array<AdvancedFilterType> => {
  let filterFields: Array<AdvancedFilterType> = []
  let filters: Array<string> = []
  if (pageType === 'record-list' && recordType) {
    filterFields = getFilterFields(recordType)
    if (recordType === 'site') {
      filters = ['allocated_user', 'name', 'site.location']
    } else if (recordType === 'organisation') {
      filters = ['name']
    } else if (recordType === 'service') {
      filters = ['name', 'site.location']
    }
  } else {
    filters = ['allocated_user', 'name', 'site.location']
    filterFields = getFilterFields('site')
  }

  if (filterFields) {
    return filterFields.filter(field => filters.includes(field.name))
  }
  return filterFields
}

export const preLoadFiltersByRole = async (
  presetFilterConfig: Array<AdvancedFilterType>,
  userRole: 'Updater'|'Editor'|'Administrator',
  pageType?: FilterPageType,
): Promise<{ [x: string]: AdvancedFilterType }> => {
  let users: AxiosResponse<{
      results: [{
        id: number,
        username: string,
        first_name: string,
        last_name: string,
      }],
      next: string
    }>
  try {
    users = await userService.list({ offset: 0, limit: 0, recordType: 'site' })
  } catch (e) {
    return {}
  }

  const loggedInUser = useAuth()
  let defaultFilters = {}
  presetFilterConfig.forEach(presetFilter => {
    if (presetFilter.name === 'allocated_user' && users.data) {
      const SPUDUser = users.data.results.find(user => user.username === loggedInUser.user.username)
      if (SPUDUser && userRole !== 'Administrator') {
        presetFilter = {
          ...presetFilter,
          value: [
            {
              id: SPUDUser.id,
              name: `${SPUDUser.first_name} ${SPUDUser.last_name}`,
            }],
        }
      } else {
        presetFilter = {
          ...presetFilter,
          value: [],
        }
      }
    } else if (presetFilter.name === 'name') {
      presetFilter = {
        ...presetFilter,
        searchOperator: 'contains',
      }
    } else if (presetFilter.name === 'site.name') {
      presetFilter = {
        ...presetFilter,
        searchOperator: 'contains',
      }
    } else if (presetFilter.name === 'site.location') {
      presetFilter = {
        ...presetFilter,
        value: {},
      }
    } else if (presetFilter.name === 'site.status') {
      presetFilter = {
        ...presetFilter,
        value: [],
      }
    }
    defaultFilters = { ...defaultFilters, [`${presetFilter.name}-0`]: presetFilter }
  })
  if (pageType === 'dashboard' && userRole !== 'Administrator') {
    defaultFilters = {
      ...defaultFilters,
      'due_for_update-0': {
        type: 'toggle',
        label: 'Due for update',
        name: 'due_for_update',
        value: true,
        defaultValue: false,
        default_order: 'last_updated_date',
      },
    }
  }
  return defaultFilters
}

const customStyle = {
  control: () => ({
    border: '1px solid #3a8ae8',
    backgroundColor: '#f6f6f6',
    borderRadius: 3,
    display: 'flex',
    padding: 1,
    marginTop: 1,
  }),
  menuList: () => ({
    textAlign: 'left',
  }),
}

const capitaliseText = (value: string): string | undefined => {
  if (value) {
    return value?.charAt(0).toUpperCase() + value?.slice(1)
  }
  return value
}

export const formatFilterFieldSummaryValue = <T, >(
  filterField: AdvancedFilterType<T | string>,
) => {
  if (filterField.type === 'toggle') {
    return filterField.value ? 'Enabled' : 'Disabled'
  } else if (typeof filterField.value === 'string') {
    if (filterField.searchOperator) {
      return `${SEARCH_OPERATOR_MAP[filterField.searchOperator]}: ${filterField.value}`
    }
    return filterField.value
  } else if (Array.isArray(filterField.value)) {
    if (filterField.name === 'allocated_user' && filterField.value.length > 1) {
      return 'Multiple'
    }
    return filterField.value.flatMap(fieldValue => fieldValue.name).join(', ')
  } else if (typeof filterField.value === 'object') {
    const filterObjectValue = filterField.value as {[x: string]: unknown}
    const numberOfKeys = Object.keys(filterObjectValue).length
    return Object.entries(filterObjectValue).map(([filterType, filterValue], index) => {
      let formattedFilterValueText = ''
      const filterValueSearchOperator = filterValue as {searchOperator: string, value: string}
      if (filterField.name.includes('location')) {
        if (filterType === 'suburbText' && filterObjectValue && filterValueSearchOperator.value) {
          formattedFilterValueText = 'Suburb ' +
            `(${filterValueSearchOperator.searchOperator}): ${filterValueSearchOperator.value}`
        } else if (Array.isArray(filterValue) && filterValue.length > 0) {
          const multiValues = filterValue.flatMap(fieldValue => fieldValue.name).join(', ')
          formattedFilterValueText = `${capitaliseText(filterType)}: ${multiValues}`
        } else if (typeof filterValue === 'string' && filterValue) {
          formattedFilterValueText = `${capitaliseText(filterType)}: ${filterValue}`
        }
      } else if (filterField.name.includes('date')) {
        if (filterType === 'dateSearchOperator' && filterValue) {
          formattedFilterValueText = `Search operator (${filterValue}) `
        } else if (filterType === 'fromDate') {
          formattedFilterValueText = `From date :  ${filterValue} + `
        } else {
          formattedFilterValueText = `${capitaliseText(filterType)}: ${filterValue}`
        }
      } else if (filterField.name.includes('iss_id')) {
        formattedFilterValueText = `${filterValue}`
      }
      const objectFilterValue = filterValue as {[x: string]: unknown} & Array<unknown>
      if ((index + 1) < numberOfKeys && (objectFilterValue?.value || objectFilterValue.length > 0)) {
        if (filterValueSearchOperator?.value) {
          return `${formattedFilterValueText} + `
        }
        return formattedFilterValueText
      } else {
        return formattedFilterValueText
      }
    })
  }
  return null
}

export const fetchRequiredFields = (recordType: string) => {
  const formConfig = getFormConfig(recordType)
  let requiredFieldNames: Array<string> = []
  formConfig?.forEach(config => {
    const requiredFields = config.fields.filter(field => field.highlight).map(requiredField => requiredField.name)
    requiredFieldNames = [...requiredFieldNames, ...requiredFields]
  })
  return requiredFieldNames
}

export const fetchFilterField = (
  filterField: AdvancedFilterType,
  onChangeCallback: (
    filterField: AdvancedFilterType,
    filterValue: FilterValueType,
    searchOperator?: SearchOperators
  ) => void,
  recordType: string,
) => {
  switch (filterField.type) {
  case 'text':
    if (filterField.additionalControls) {
      return (
        <TextInputWithSearchOperators
          filterField={filterField}
          valueOverride={{
            value: filterField.value as string,
            searchOperator: filterField.searchOperator as string,
          }}
          onChangeCallback={(filterField, filterValue, searchOperator) => {
            onChangeCallback(filterField, filterValue, searchOperator)
          }}
        />
      )
    }
    return (
      <div>
        <SPUDInputField
          type={filterField.type}
          name={filterField.name}
          placeholder={filterField.label}
          label={<Title level={5} marginTop='5px'>{filterField.label}</Title>}
          value={filterField.value}
          highlight={false}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            onChangeCallback(filterField, event.target.value)}
          fullwidth
        />
        {filterField?.validation && !filterField.validation(filterField.value)
          ? <Alert type="error">
            {filterField.errorMessage}
          </Alert>
          : null }
      </div>
    )
  case 'multiselect':
    if (filterField.options) {
      return (
        <SPUDAutoComplete
          customStyleOverride={customStyle}
          label={filterField.label}
          options={filterField.options}
          selectedOption={filterField.value as Array<Option>}
          isMulti={true}
          recordType={recordType}
          fieldName={filterField.name}
          formatDisplayName={filterField?.formatDisplayName}
          disabled={false}
          onChange={(users) => onChangeCallback(filterField, users as Array<Option>)}
          customIdentifier='id'
          isClearable={false}
        />
      )
    }
    return (
      <SPUDAutoComplete
        customStyleOverride={customStyle}
        label={filterField.label}
        fetchOptionsAsync={filterField.optionFunction}
        selectedOption={filterField.value as Array<Option>}
        asyncParams={filterField?.asyncParams}
        isMulti={true}
        recordType={recordType}
        fieldName={filterField.name}
        formatDisplayName={filterField?.formatDisplayName}
        disabled={false}
        onChange={(users) => onChangeCallback(filterField, users as Array<Option>)}
        customIdentifier='id'
        isClearable={false}
      />
    )
  case 'dropdown':
    return (
      <SPUDAutoComplete
        customStyleOverride={customStyle}
        label={filterField.label}
        options={filterField.options}
        selectedOption={filterField.value as Array<Option>}
        isMulti={false}
        recordType={recordType}
        fieldName={filterField.name}
        formatDisplayName={filterField?.formatDisplayName}
        disabled={false}
        onChange={(users) => onChangeCallback(filterField, users as Array<Option>)}
        customIdentifier='id'
        isClearable={false}
      />
    )
  case 'toggle':
    if (filterField.value === 'false') {
      filterField.value = false
    } else if (filterField.value === 'true') {
      filterField.value = true
    }
    if (filterField.requiredFields) {
      filterField.requiredFields = fetchRequiredFields(recordType)
    }
    return (
      <SPUDToggle
        label={filterField.label}
        checked={filterField.value}
        onChange={(toggleValue: boolean) => onChangeCallback(filterField, toggleValue)}
      />
    )
  case 'customComponent':
    return fetchCustomFilter(
      filterField.customComponent?.name,
      {
        ...filterField.customComponent?.props,
        onChangeCallback: onChangeCallback,
        filterField: filterField,
      },
    )
  default:
    return <div>Test</div>
  }
}

export const getFilterIndex = (
  filterValues: {[x: string]: AdvancedFilterType},
  activeFilter: AdvancedFilterType,
  fetchIndex: boolean,
) => {
  let nextIndex = {
    [activeFilter.name]: 0,
  }
  Object.entries(filterValues).forEach(([key]) => {
    if (key.includes(activeFilter.name)) {
      const filterNameAndIndex = key.split('-')
      const name = filterNameAndIndex[0]
      const index = filterNameAndIndex[1]
      if (fetchIndex) {
        nextIndex = { ...nextIndex, [name]: parseInt(index) }
      } else {
        nextIndex = { ...nextIndex, [name]: parseInt(index) + 1 }
      }
    }
  })
  return nextIndex
}

/**
 * This method is used to remove 'allocated' and 'all' filters
 * from the active filters, as only one allocated or all
 * filter may be enabled at any one time.
 * @param newFilters
 * @param addedFilter
 */
export const allocatedUserFilterSwitches = (
  newFilters: {[x: string]: AdvancedFilterType},
  addedFilter: AdvancedFilterType,
): {[x: string]: AdvancedFilterType} => {
  // Finds the set allocated user filter e.g. allocated to SPUD Updater user
  const allocatedUserFilter = Object.keys(newFilters)
    .find(filter => filter.includes('allocated_user'))

  // Finds the set allocated to me filter
  const allocatedToMeFilter = Object.keys(newFilters)
    .find(filter => filter.includes('allocated') && !filter.includes('allocated_user'))

  // Finds the all filter
  const allFilter = Object.keys(newFilters)
    .find(filter => filter.includes('all') && !filter.includes('allocated_user') && !filter.includes('allocated'))

  // If allocated to me is selected to be added
  // but the allocated user is already set,
  // then delete the allocated user filter
  if (addedFilter.name === 'allocated' && allocatedUserFilter) {
    delete newFilters[allocatedUserFilter]

  // If allocated to me is selected to be added
  // but the all filter is already set,
  // then delete the all filter
  } else if (addedFilter.name === 'allocated' && allFilter) {
    delete newFilters[allFilter]

  // If all is selected to be added
  // but the allocated user or the allocated
  // to me is already set, then delete either
  // of those that is set
  } else if (addedFilter.name === 'all' && (allocatedToMeFilter || allocatedUserFilter)) {
    allocatedToMeFilter
      ? delete newFilters[allocatedToMeFilter]
      : allocatedUserFilter && delete newFilters[allocatedUserFilter]

  // If the allocated user is selected to be added
  // but the all or the allocated,
  // then delete either of those that is set
  } else if (
    addedFilter.name === 'allocated_user' &&
    (allocatedToMeFilter || allFilter)
  ) {
    allFilter
      ? delete newFilters[allFilter]
      : allocatedToMeFilter && delete newFilters[allocatedToMeFilter]
  }
  return newFilters
}

export const statusQuickFilterSwitches = (
  newFilters: {[x: string]: AdvancedFilterType},
  addedFilter: AdvancedFilterType,
  userRole: 'Updater'|'Editor'|'Administrator',
  pageType: FilterPageType,
) => {
  const userPresetFilters = fetchQuickFilterPresetByRole(userRole, pageType)
  const statusPresets = userPresetFilters.filter(preset => preset.group === 'status')
  statusPresets.forEach((statusPreset) => {
    if (addedFilter.name !== 'allocated') {
      const foundStatusFilter = Object.keys(newFilters)
        .find(filter => filter.includes(statusPreset.name) && !filter.includes(addedFilter.name))
      if (foundStatusFilter) {
        delete newFilters[foundStatusFilter]
      }
    }
  })
  return newFilters
}
