import React, { useEffect, useState } from 'react'
import { Col, Link, Row, Button, Title, Badge } from '@ix/ix-ui'
import styled from 'styled-components'
import pluralize from 'pluralize'
import {
  SearchListResultColumn,
  SearchListResultColumnSeperator,
  SearchListResultList,
  SearchListResultListItem,
} from './SearchResultsPage'
import { fetchCorrectIdToDisplay } from '../helpers/record'
import { SPUDQuickSearchResponseType } from '../services/transactions.service'

type QuickSearchListProps = {
  searchResults: {
    [x: string]: Array<SPUDQuickSearchResponseType>
  },
  loading: boolean,
  onDismiss: () => void,
  totalNoRecords: number,
  searchTerm: string,
}

const QuickSearchListContainer = styled.div`
  position: absolute;
  z-index: 4;
  background-color: #fff;
  left: 10%;
  right: 10%;
  width: 80%;
  border: 2px solid ${props => props.theme.colors.accentColor};
  border-radius: 3px;
  top: 0;
`

const QuickSearchListHeader = styled.div`
  background-color: ${props => props.theme.colors.accentColor};
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1em;
`

const QuickSearchListSeeMoreRow = styled(Row)`
  border-top: 1px solid ${props => props.theme.colors.accentColor};
`

function QuickSearchList (
  {
    searchResults,
    loading,
    onDismiss,
    totalNoRecords,
    searchTerm,
  }: QuickSearchListProps) {
  const [sites, setSites] = useState<Array<SPUDQuickSearchResponseType>>([])
  const [services, setServices] = useState<Array<SPUDQuickSearchResponseType>>([])
  const [organisations, setOrganisations] = useState<Array<SPUDQuickSearchResponseType>>([])

  useEffect(() => {
    if (Object.keys(searchResults).length > 0) {
      if (searchResults?.sites?.length) {
        setSites(searchResults?.sites.slice(0, 50) as Array<SPUDQuickSearchResponseType>)
      } else {
        setSites([])
      }
      if (searchResults?.services?.length) {
        setServices(searchResults?.services.slice(0, 50) as Array<SPUDQuickSearchResponseType>)
      } else {
        setServices([])
      }
      if (searchResults?.organisations?.length) {
        setOrganisations(searchResults?.organisations.slice(0, 50) as Array<SPUDQuickSearchResponseType>)
      } else {
        setOrganisations([])
      }
    }
  }, [searchResults?.sites, searchResults?.services, searchResults?.organisations])

  return <QuickSearchListContainer>
    <Row>
      <QuickSearchListHeader>
        <Title level={4}>
          <span style={{ marginRight: 5 }}>Search results</span>
          <Badge
            active
            block
            content={`${totalNoRecords} ${pluralize('record', totalNoRecords)} found`}
          />
        </Title>
        <Button onClick={onDismiss}>
          X
        </Button>
      </QuickSearchListHeader>
    </Row>
    {!loading && <Row>
      <SearchListResultColumn>
        <Title level={5} align='center'>
          Sites{' '}
          <Badge
            active
            block
            content={`${sites.length} ${sites.length === 50 ? '+' : ''} found`}
          />
        </Title>
        <SearchListResultColumnSeperator />
        <SearchListResultList>
          {sites.map((returnedValue: SPUDQuickSearchResponseType) =>
            <SearchListResultListItem key={returnedValue.id}>
              <Row>
                <Col>
                  <Link
                    to={`/records/${returnedValue.record_type}/record/${
                      fetchCorrectIdToDisplay(returnedValue.id, returnedValue.iss_id)}`}
                    onClick={() => onDismiss()}
                  >
                    {returnedValue.name || 'Unknown record name'}
                  </Link>
                </Col>
              </Row>
              <Row>
                <Col>
                  ({returnedValue.suburb})
                </Col>
              </Row>
            </SearchListResultListItem>)}
        </SearchListResultList>
      </SearchListResultColumn>
      <SearchListResultColumn>
        <Title level={5} align='center'>
          Services{' '}
          <Badge
            active
            block
            content={`${services.length} ${services.length === 50 ? '+' : ''} found`}
          />
        </Title>
        <SearchListResultColumnSeperator />
        <SearchListResultList>
          {services.map((returnedValue: SPUDQuickSearchResponseType) =>
            <SearchListResultListItem key={returnedValue.id}>
              <Row>
                <Col>
                  <Link
                    to={`/records/${returnedValue.record_type}/record/${
                      fetchCorrectIdToDisplay(returnedValue.id, returnedValue.iss_id)}`}
                    onClick={() => onDismiss()}
                  >
                    {returnedValue.name || 'Unknown record name'}
                  </Link>
                </Col>
              </Row>
              <Row>
                <Col>
                  ({returnedValue.site})
                </Col>
              </Row>
            </SearchListResultListItem>)}
        </SearchListResultList>
      </SearchListResultColumn>
      <SearchListResultColumn>
        <Title level={5} align='center'>
          Organisation{' '}
          <Badge
            active
            block
            content={`${organisations.length} ${organisations.length === 50 ? '+' : ''} found`}
          />
        </Title>
        <SearchListResultColumnSeperator />
        <SearchListResultList>
          {organisations.map((returnedValue: SPUDQuickSearchResponseType) =>
            <SearchListResultListItem key={returnedValue.id}>
              <Link
                to={`/records/${returnedValue.record_type}/record/${
                  fetchCorrectIdToDisplay(returnedValue.id, returnedValue.iss_id)}`}
                onClick={() => onDismiss()}
              >
                {returnedValue.name || 'Unknown record name'}
              </Link>
            </SearchListResultListItem>)}
        </SearchListResultList>
      </SearchListResultColumn>
    </Row>
    }
    <QuickSearchListSeeMoreRow>
      <Col direction='row' justify='center'>
        <Link to={`/search/${searchTerm}`}>
          <Button onClick={onDismiss}>
            Expand search to see more results
          </Button>
        </Link>
      </Col>
    </QuickSearchListSeeMoreRow>
  </QuickSearchListContainer>
}

export default QuickSearchList
