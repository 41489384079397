import React, { useEffect, useMemo, useState } from 'react'
import {
  Row,
  Col,
  Button,
  Title,
  Alert,
  Text,
} from '@ix/ix-ui'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRight,
  faLevelUpAlt,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { SPUDInputField } from '../../../helpers/record'
import EditOpeningHoursPopup from './EditOpeningHoursPopup'
import {
  DAYS_OF_THE_WEEK,
  convert24hrTo12HrFormat,
  groupDays,
  renderNote,
  openCloseType,
  OpeningHoursSummaryContent,
  OpeningHoursSummaryChipTime, OpeningHoursSummaryChipDay, OpeningHoursSummaryChip,
  TimeType,
  checkForOverlappingTimes,
} from './OpeningHours.service'
import { SPUDSiteRecordDetails } from '../../../../@types/Site.type'
import BulkUpdateRecordChangeReview from '../../forms/BulkUpdateRecordChangeReview'
import { BulkServiceProviderUpdateType } from '../../../services/spud.service'
import SiteOpeningHours from './SiteOpeningHours'
import TimePicker from './TimePicker'

export type OpenHoursType = {
  id?: string,
  day: string,
  open: string,
  close: string,
  note: string,
}

export type SPUDOpeningHoursProps = {
  title: string,
  recordType: string,
  siteValues: SPUDSiteRecordDetails,
  disabled: boolean,
  bulkUpdateChanges: BulkServiceProviderUpdateType,
  externalForm: boolean,
}

const DaysOfTheWeekButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1em;
  & > div {
    width: 100%;
    text-align: left;
  }

  & button:last-child {
    margin-right: 0;
  }
`

const DayOfTheWeekButton = styled(Button)`
  margin: 5px;
  width: 100%;
`

const OpeningHoursAddNewWidget = styled.div`
  background-color: #f3f3f3;
  border-radius: 3px;
  padding: 10px;
`

const OpeningHoursSummary = styled.div.attrs({
  className: 'form-opening-hours',
})`
  background-color: #F3F3F3;
  border-radius: 3px;
  padding: 10px;
  min-height: 200px;
`

const OpeningHoursSummaryChipButton = styled.button`
  margin-left: 1em;
  border: none;
  background: none;
  cursor: pointer;
  :hover {
    background: #e3edfa;
  }
`

const AngledArrowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1em;
`

const OpeningHoursLinkButton = styled(Button)`
  margin: 7px 5px;
  border: none;
  background: none;
  text-decoration: underline;
  padding: 0;
  :hover {
    text-decoration: none;
    border: none;
    background: none;
  }
  :focus {
    box-shadow: none;
  }
`

const BoldTitle = styled(Title)`
  font-weight: bolder;
`

const OpeningHoursButton = styled(Button)`
  background-color: #E0E6EE;
  color: #222;
  font-weight: bold;

  :hover {
   color: white;
   background: #3A8AE8;
  }
`
const BoldDayOfTheWeekButton = styled(DayOfTheWeekButton)`
  font-weight: bold;

  :hover {
   color: white;
   background: #3A8AE8;
  }
`

function SPUDOpeningHours (
  {
    title,
    siteValues,
    disabled,
    recordType,
    bulkUpdateChanges,
    externalForm,
  }: SPUDOpeningHoursProps): React.ReactElement {
  const [daysSelected, setDaysSelected] = useState<{[x: string]: boolean}>({
    Sunday: false,
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
    'Public Holiday': false,
  })
  const [showPopper, setShowPopper] = useState('')
  const [globalComment, setGlobalComment] = useState('')
  const [addNewTimes, setAddNewTimes] = useState<{ open: string, close: string}>({
    open: '09:00:00',
    close: '17:00:00',
  })
  const [invalidDateConfig, setInvalidDateConfig] = useState(false)
  const [siteOpeningHours, setSiteOpeningHours] = useState<Array<OpenHoursType>>([])
  const [tempOpeningHours, setTempOpeningHours] = useState([])
  const [clearTimePicker, setClearTimePicker] = useState(false)

  useEffect(() => {
    siteValues?.opening_hours && setSiteOpeningHours(siteValues.opening_hours)
  }, [siteValues])

  const { control, getValues, setValue } = useFormContext()
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'opening_hours',
  })

  const clearDays = () => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Public Holiday']
    const newDaysSelected = { ...daysSelected }
    for (const day of days) {
      newDaysSelected[day] = false
    }
    setDaysSelected(newDaysSelected)
  }

  const loadDayPreset = (preset: 'mon-fri'|'seven-days'|'weekend-public-holidays') => {
    let days: Array<string> = []
    switch (preset) {
    case 'mon-fri':
      days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']
      break
    case 'seven-days':
      days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Public Holiday']
      break
    case 'weekend-public-holidays':
      days = ['Sunday', 'Saturday', 'Public Holiday']
    }

    // Resetting the days so then when a preset is selected only those days are selected
    const newDaysSelected: { [x: string]: boolean } = {
      Sunday: false,
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
      'Public Holiday': false,
    }
    for (const day of days) {
      newDaysSelected[day] = true
    }
    setDaysSelected(newDaysSelected)
  }

  const [initOpenClose, setInitOpenClose] = useState<{ open: TimeType; close: TimeType }>({
    open: { hour: '09', minute: '00', amPm: 'AM' },
    close: { hour: '05', minute: '00', amPm: 'PM' },
  })

  const resetTimePicker = () => {
    setClearTimePicker(true)
    setInitOpenClose({
      open: { hour: '09', minute: '00', amPm: 'AM' },
      close: { hour: '05', minute: '00', amPm: 'PM' },
    })
  }

  const loadTimePreset = (preset: '24hrs'|'9-5'): void => {
    if (preset === '24hrs') {
      setClearTimePicker(true)
      setInitOpenClose({
        open: { hour: '12', minute: '00', amPm: 'AM' },
        close: { hour: '12', minute: '00', amPm: 'AM' },
      })
      setAddNewTimes({ open: '00:00:00', close: '00:00:00' })
    } else {
      resetTimePicker()
      setAddNewTimes({ open: '09:00:00', close: '17:00:00' })
    }
  }

  const getIndexes = (day: string): number[] => {
    return fields.reduce((accumulator: number[], field, index) => {
      // Ignoring because even the shape of field is known the type can't be set
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (field.day === day) {
        accumulator.push(index)
      }
      return accumulator
    }, [])
  }

  const daysSet = useMemo(
    () => {
      let areDaysSet = false
      for (const day of Object.keys(daysSelected)) {
        if (daysSelected[day]) {
          areDaysSet = true
          break
        }
      }
      return areDaysSet
    },
    [daysSelected])

  const updateOpeningsHoursWithGlobalComment = (day: string) => {
    const indexes = getIndexes(day)
    for (const index of indexes) {
      if (globalComment) {
        setValue(`opening_hours.${index}.note`, globalComment)
      }
    }
  }

  const commentAlreadyExists = useMemo(
    () => {
      const daysWithNotes = []
      for (const day of Object.keys(daysSelected)) {
        if (daysSelected[day]) {
          // Ignoring because even the shape of field is known the type can't be set
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const field = fields.find(field => field.day === day && field.note)
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          field && daysWithNotes.push(field.day)
        }
      }
      return daysWithNotes
    },
    [daysSelected])

  const acceptChanges = (key: string, value: unknown) => {
    setValue(key, value)
  }

  const validateTimeConfig = (days: { [x: string]: boolean }, time?: string, isOpen?: boolean): boolean => {
    let timeSlotAlreadyExists = false
    for (const day of Object.keys(days)) {
      if (!timeSlotAlreadyExists && days[day]) {
        if (time) {
          if (isOpen && addNewTimes.close) {
            timeSlotAlreadyExists = checkForOverlappingTimes(
              {
                close: addNewTimes.close,
                open: time,
              },
              day, getValues('opening_hours'))
          } else if (!isOpen && addNewTimes.open) {
            timeSlotAlreadyExists = checkForOverlappingTimes(
              {
                close: time,
                open: addNewTimes.open,
              },
              day, getValues('opening_hours'))
          }
        } else {
          timeSlotAlreadyExists = checkForOverlappingTimes(
            {
              close: addNewTimes.close,
              open: addNewTimes.open,
            },
            day, getValues('opening_hours'))
        }
      }
    }
    return timeSlotAlreadyExists
  }

  useEffect(() => {
    if (addNewTimes.open > addNewTimes.close) {
      setInvalidDateConfig(true)
    } else {
      setInvalidDateConfig(validateTimeConfig(daysSelected, addNewTimes.open, true))
    }
  }, [daysSelected])

  const handleTimeSelect = (time: string, type: openCloseType) => {
    setAddNewTimes((prevTimes) => {
      const newTimes = { ...prevTimes, [type]: time }
      if (newTimes.open > newTimes.close) {
        setInvalidDateConfig(true)
      } else {
        if (type === 'open') {
          setInvalidDateConfig(validateTimeConfig(daysSelected, time, true))
        } else {
          setInvalidDateConfig(validateTimeConfig(daysSelected, time, false))
        }
      }
      return newTimes
    })
  }

  return (
    <div>
      <Row>
        <Col direction='row' align='baseline'>
          <Title level={4}>{title}</Title>
        </Col>
      </Row>
      <Row>
        <Col>
          <OpeningHoursAddNewWidget>
            <Row justify="space-between" align="center">
              <BoldTitle level={4}>Select Day(s)</BoldTitle>
              <OpeningHoursLinkButton onClick={() => {
                clearDays()
                setAddNewTimes({
                  open: '09:00:00',
                  close: '17:00:00',
                })
                setGlobalComment('')
                setInvalidDateConfig(false)
                resetTimePicker()
              }}
              >
                Clear
              </OpeningHoursLinkButton>
            </Row>
            <Row>
              <Col>
                <DaysOfTheWeekButtonContainer>
                  <Row>
                    <Col direction="row" align="baseline">
                      <OpeningHoursButton
                        onClick={() => loadDayPreset('mon-fri')}
                        title="This will select the days Monday to Friday"
                      >
                        Mon - Fri
                      </OpeningHoursButton>
                      <OpeningHoursButton
                        onClick={() => loadDayPreset('seven-days')}
                        title="This will select the days Monday to Friday including the weekends"
                      >
                        Seven Days
                      </OpeningHoursButton>
                      <OpeningHoursButton
                        onClick={() => loadDayPreset('weekend-public-holidays')}
                        title="This will select the weekends and public holidays"
                      >
                        Sat, Sun, Public Holidays
                      </OpeningHoursButton>
                    </Col>
                  </Row>
                  <Row style={{ marginLeft: '-10px' }}>
                    <Col direction="row" align="baseline">
                      {DAYS_OF_THE_WEEK.map((day) => (
                        <BoldDayOfTheWeekButton
                          key={day.name}
                          active={daysSelected?.[day.name]}
                          onClick={() => {
                            const newDaysSelected = { ...daysSelected, [day.name]: !daysSelected[day.name] }
                            setDaysSelected(newDaysSelected)
                          }}
                        >
                          {day.name}
                        </BoldDayOfTheWeekButton>
                      ))}
                    </Col>
                  </Row>
                </DaysOfTheWeekButtonContainer>
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{ width: '100%', marginTop: '-30px' }}>
                  <BoldTitle level={4}>Select Times</BoldTitle>
                </div>
                <Row>
                  <Col direction='row' align='center'>
                    <OpeningHoursButton
                      primary
                      onClick={() => loadTimePreset('24hrs')}
                      title="This will prefill the open and close with 12:00 am to 12:00 am"
                    >
                      24hrs
                    </OpeningHoursButton>
                    <OpeningHoursButton
                      primary
                      onClick={() => loadTimePreset('9-5')}
                      title="This will prefill the open and close with 9:00 am to 5:00 pm"
                    >
                      9 - 5
                    </OpeningHoursButton>
                    <div style={{ marginLeft: '10px', fontSize: '0.7em', fontWeight: 'bold' }}>OR:</div>
                  </Col>
                </Row>
                <Row>
                  <Col direction='row' align='center'>
                    <TimePicker
                      id='open'
                      defaultValue={initOpenClose.open}
                      onTimeSelect={(time) => handleTimeSelect(time, 'open')}
                      clearTimePicker={clearTimePicker}
                    />
                    <div style={{ margin: '0 10px' }}>
                      <FontAwesomeIcon icon={faArrowRight as IconProp} />
                    </div>
                    <TimePicker
                      id='close'
                      defaultValue={initOpenClose.close}
                      onTimeSelect={(time) => handleTimeSelect(time, 'close')}
                      clearTimePicker={clearTimePicker}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                {invalidDateConfig && <Alert type='error' style={{ marginTop: 0 }}>
                 Open/close times are invalid.
                 Check that the times don&apos;t overlap or close is before open
                </Alert>}
              </Col>
            </Row>
            <Row>
              <Col>
                <SPUDInputField
                  label={<Title level={4} marginTop='5px'>Comment <Text>
                    (if applicable for all selected days)
                  </Text></Title>}
                  value={globalComment}
                  name='opening_hours_global_comment'
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => setGlobalComment(event.target.value)}
                  fullwidth
                />
              </Col>
            </Row>
            <Row>
              <Col>
                {commentAlreadyExists.length > 0 && <Alert type='error'>There’s already a comment added to {
                  commentAlreadyExists.map(day => `${day} `)
                } entering global comment will override anything existing</Alert>}
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  title='Add opening hours'
                  active={daysSet && !invalidDateConfig}
                  disabled={invalidDateConfig || !daysSet}
                  style={{ width: '100%' }}
                  onClick={() => {
                    const selectedDays = Object.keys(daysSelected)
                    for (const selectedDay of selectedDays as Array<string>) {
                      if (daysSelected[selectedDay]) {
                        const indexes = getIndexes(selectedDay)
                        const note: Array<string> = []
                        indexes.forEach(index => {
                          note.push(getValues(`opening_hours.${index}.note`))
                        })
                        append({
                          day: selectedDay,
                          open: addNewTimes.open,
                          close: addNewTimes.close,
                          note: globalComment || note?.[0] || '',
                        })
                        updateOpeningsHoursWithGlobalComment(selectedDay)
                      }
                    }
                    clearDays()
                    setAddNewTimes({ open: '09:00:00', close: '17:00:00' })
                    resetTimePicker()
                    setGlobalComment('')
                  }}
                >
                  Add
                </Button>
              </Col>
            </Row>
          </OpeningHoursAddNewWidget>
        </Col>
      </Row>
      {siteOpeningHours.length > 0 && recordType === 'service' &&
        <SiteOpeningHours
          siteOpeningHours={siteOpeningHours}
          bulkUpdateForm={externalForm}
        />}
      <Row>
        <Col direction='row' justify='space-between' align='center'>
          <Title level={4}>Opening Hours Summary</Title>
          <OpeningHoursLinkButton onClick={() => {
            if (!tempOpeningHours.length) {
              setTempOpeningHours(getValues('opening_hours'))
              setValue('opening_hours', [])
            } else {
              setValue('opening_hours', tempOpeningHours)
              setTempOpeningHours([])
            }
          }}
          >
            {tempOpeningHours.length ? 'Undo' : 'Clear all'}
          </OpeningHoursLinkButton>
        </Col>
      </Row>
      <Row>
        <Col>
          <OpeningHoursSummary aria-label='Opening hours summary'>
            {DAYS_OF_THE_WEEK.map((dayOfTheWeek, index) => (
              groupDays(fields)?.[dayOfTheWeek.name]?.length &&
              <OpeningHoursSummaryContent
                key={`${dayOfTheWeek.name}_${index}`}
              >
                <AngledArrowWrapper>
                  <FontAwesomeIcon size='2x' rotation={90} icon={faLevelUpAlt as IconProp} color='#A3A3A3'/>
                </AngledArrowWrapper>
                <OpeningHoursSummaryChip
                  clicked={showPopper === dayOfTheWeek.name}
                  onClick={() => setShowPopper(dayOfTheWeek.name)}
                >
                  <div style={{ display: 'flex' }}>
                    <div>
                      <OpeningHoursSummaryChipDay>
                        {groupDays(fields)?.[dayOfTheWeek.name]?.length ? dayOfTheWeek.name : ''} |
                      </OpeningHoursSummaryChipDay>
                      {getIndexes(dayOfTheWeek.name).map((index: number) => (
                        <OpeningHoursSummaryChipTime key={`${dayOfTheWeek.name}_${index}`}>
                          {convert24hrTo12HrFormat(getValues(`opening_hours.${index}.open`))} - {
                            convert24hrTo12HrFormat(getValues(`opening_hours.${index}.close`))}
                          {getIndexes(dayOfTheWeek.name).length > 1 && '; '}
                        </OpeningHoursSummaryChipTime>
                      ))}
                      <span>{renderNote(dayOfTheWeek.name, false, [], fields)}</span>
                    </div>
                  </div>
                  <OpeningHoursSummaryChipButton
                    disabled={disabled}
                    onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                      event.preventDefault()
                      remove(getIndexes(dayOfTheWeek.name))
                      setShowPopper('')
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes as IconProp} />
                  </OpeningHoursSummaryChipButton>
                  {showPopper === dayOfTheWeek.name &&
                    <EditOpeningHoursPopup
                      indexes={getIndexes(dayOfTheWeek.name)}
                      day={dayOfTheWeek.name}
                      append={append}
                      update={update}
                      remove={remove}
                      onClose={() => {
                        setShowPopper('')
                      }}
                      disabled={disabled}
                    />}
                </OpeningHoursSummaryChip>
              </OpeningHoursSummaryContent>
            ))}
          </OpeningHoursSummary>
        </Col>
      </Row>
      {bulkUpdateChanges && <Row>
        <Col>
          <BulkUpdateRecordChangeReview
            bulkUpdateChanges={bulkUpdateChanges}
            setValue={acceptChanges}
            fieldKey='opening_hours_simple'
            fieldLabel={title}
            currentFieldValue={getValues('opening_hours_simple')}
            allowChange={false}
            currentOpeningHours={getValues('opening_hours')}
          />
        </Col>
      </Row>}
    </div>
  )
}

export default SPUDOpeningHours
