import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Title,
} from '@ix/ix-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import styled from 'styled-components'
import { getFormConfig, SPUDInputField } from '../../../../helpers/record'
import SPUDAutoComplete from '../../../../components/General/SPUDAutoComplete'
import { Field, Option } from '../../../../components/forms/AddEditRecord.type'
import SPUDTextArea from '../../../../components/General/SPUDTextArea'
import { ControllerRenderProps } from 'react-hook-form'

type Props = {
  recordType: string,
  findFromCallback: (findFromFields: {[x: string]: unknown}) => void
  replaceWithCallback: (replaceWithFields: {[x: string]: unknown}) => void,
  maxWidth: string | number
}

const Panel = styled.div`
  padding: 5px;
  background: ${props => props.theme.colors.accentColor};
`

const StatusDropdownContainer = styled.div`
  margin: 1em 0;
`

const STATUS_OPTIONS = [
  { id: 'draft', name: 'Draft' },
  { id: 'pending', name: 'Pending' },
  { id: 'published', name: 'Published' },
]

function BulkUpdateFindReplaceWidget ({ recordType, findFromCallback, replaceWithCallback, maxWidth }: Props) {
  const [addFields, setAddFields] = useState<Field[] | undefined>([])
  const [copyReplaceFields, setCopyReplaceFields] = useState<Field[] | undefined>([])
  const [findFromFields, setFindFromFields] = useState<{[x: string]: unknown}>({})
  const [lockStatus, setLockStatus] = useState(false)
  const [lockFindReplaceField, setLockFindReplaceField] = useState(false)
  const [replaceWithFields, setReplaceWithFields] = useState<{[x: string]: unknown}>({})

  useEffect(() => {
    const formElements = getFormConfig(recordType)
    const bulkAddFields = formElements?.flatMap(
      group => group.fields.filter(
        field => field.bulkAddEnabled,
      ),
    )
    const bulkCopyAndReplaceFields = formElements?.flatMap(
      group => group.fields.filter(
        field => field.bulkUpdateEnabled,
      ),
    )
    setCopyReplaceFields(bulkCopyAndReplaceFields)
    setAddFields(bulkAddFields)
  }, [])

  const preProcessUpdatePayload = (
    field: Field,
    valueObject: {[x: string]: unknown},
  ): {[x: string]: unknown} => {
    if (field.type === 'multiselect') {
      const fieldValues = valueObject?.[field.name]
      if (Array.isArray(fieldValues)) {
        return { ...valueObject, [field.name]: fieldValues.map(fieldValue => fieldValue?.id) }
      }
    }
    return { ...valueObject, [field.name]: valueObject[field.name] }
  }

  const lockFields = (
    value: number | string | Option | Array<string | number | Option | null> | null,
    findFields: {[x: string]: unknown},
    replaceFields: {[x: string]: unknown},
  ) => {
    if (value || findFields?.status || replaceFields?.status) {
      setLockFindReplaceField(true)
    } else {
      setLockFindReplaceField(false)
    }
  }

  const lockStatusFields = (
    value: unknown,
    findFields: {[x: string]: unknown},
    replaceFields: {[x: string]: unknown},
  ) => {
    if (value || Object.keys(findFields).length || Object.keys(replaceFields).length) {
      setLockStatus(true)
    } else {
      setLockStatus(false)
    }
  }

  const fetchCurrentFormElement = (field: Field, isAdd: boolean, isReplace: boolean) => {
    const action = isAdd ? 'Add' : isReplace ? 'Replace' : 'Find'
    switch (field.type) {
    case 'multiselect':
      return (
        <SPUDAutoComplete
          label={`${action} ${field.label.toLowerCase()}`}
          selectedOption={(isAdd ? replaceWithFields?.[field.name] : findFromFields?.[field.name]) as Option}
          isMulti={true}
          disabled={lockFindReplaceField}
          fetchOptionsAsync={field.optionFunction}
          asyncParams={field.asyncParams}
          isBulkUpdate={true}
          onChange={(value) => {
            if (isAdd || isReplace) {
              const replaceFields = { ...replaceWithFields, [field.name]: value }
              if (!value) {
                delete replaceFields[field.name]
              }
              setReplaceWithFields(replaceFields)
              const processedPayload = preProcessUpdatePayload(field, replaceFields)
              replaceWithCallback(processedPayload)
              lockStatusFields(value, findFromFields, replaceFields)
            } else {
              const findFields = { ...findFromFields, [field.name]: value }
              if (!value) {
                delete findFields[field.name]
              }
              setFindFromFields(findFields)
              findFromCallback(findFields)
              lockStatusFields(value, findFields, replaceWithFields)
            }
          }}
          customStyleOverride={{
            control: () => ({
              border: '1px solid #3a8ae8',
              borderRadius: 3,
              display: 'flex',
              padding: 1,
              marginTop: 1,
              background: '#fff',
              width: (isAdd && maxWidth) || 400,
            }),
          }}
        />
      )
    case 'textarea':
      // eslint-disable-next-line no-case-declarations
      let textareaField
      if (isReplace) {
        textareaField = {
          value: replaceWithFields[field.name] || '',
          name: field.name,
          onChange: (value: React.ChangeEvent<HTMLInputElement>) => {
            const replaceFields = { ...replaceWithFields, [field.name]: value.target.value }
            if (!value.target.value) {
              delete replaceFields[field.name]
            }
            setReplaceWithFields(replaceFields)
            replaceWithCallback(replaceFields)
            lockStatusFields(value.target.value, findFromFields, replaceFields)
          },
        }
      } else {
        textareaField = {
          value: findFromFields[field.name] || '',
          name: field.name,
          onChange: (value: React.ChangeEvent<HTMLInputElement>) => {
            const findFields = { ...findFromFields, [field.name]: value.target.value }
            if (!value.target.value) {
              delete findFields[field.name]
            }
            setFindFromFields(findFields)
            findFromCallback(findFields)
            lockStatusFields(value.target.value, findFields, replaceWithFields)
          },
        }
      }
      return (
        <SPUDTextArea
          field={textareaField as ControllerRenderProps}
          label={field.label}
          disabled={lockFindReplaceField}
          minHeight='150px'
        />
      )
    case 'text':
      return (
        <SPUDInputField
          type={field.type}
          placeholder={field.label}
          label={<Title level={4} marginTop='5px'>{field.label}</Title>}
          fullwidth
          name={`${action.toLowerCase()}_${field.name}`}
          disabled={lockFindReplaceField}
          value={isReplace ? replaceWithFields[field.name] : findFromFields[field.name]}
          onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
            if (isReplace) {
              const replaceFields = { ...replaceWithFields, [field.name]: value.target.value }
              if (!value.target.value) {
                delete replaceFields[field.name]
              }
              setReplaceWithFields(replaceFields)
              replaceWithCallback(replaceFields)
              lockStatusFields(value.target.value, findFromFields, replaceFields)
            } else {
              const findFields = { ...findFromFields, [field.name]: value.target.value }
              if (!value.target.value) {
                delete findFields[field.name]
              }
              setFindFromFields(findFields)
              findFromCallback(findFields)
              lockStatusFields(value.target.value, findFields, replaceWithFields)
            }
          }}
        />
      )
    default:
      return null
    }
  }

  return <div>
    {copyReplaceFields && copyReplaceFields.length > 0 &&
      <Row align='center'>
        <Col flex={4}>
          <Row>
            <Col flex={4}>
              <Title level={4}>
                Find
              </Title>
            </Col>
            <Col></Col>
            <Col flex={4}>
              <Title level={4}>
                Replace
              </Title>
            </Col>
          </Row>
          <Row>
            <Col flex={3}>
              <StatusDropdownContainer>
                <SPUDAutoComplete
                  label='Old status'
                  options={STATUS_OPTIONS}
                  selectedOption={findFromFields?.status as Option}
                  isMulti={false}
                  highlight={lockStatus}
                  disabled={lockStatus}
                  onChange={(value) => {
                    const oldStatusFields = { ...findFromFields, status: value }
                    setFindFromFields(oldStatusFields)
                    findFromCallback(oldStatusFields)
                    lockFields(value, oldStatusFields, replaceWithFields)
                  }}
                />
              </StatusDropdownContainer>
            </Col>
            <Col justify='center' align='center'>
              <div>
                <FontAwesomeIcon icon={faArrowRight as IconProp} size='3x'/>
              </div>
            </Col>
            <Col flex={3} style={{ padding: '0 30px 0 0px' }}>
              <StatusDropdownContainer>
                <SPUDAutoComplete
                  label='New status'
                  options={STATUS_OPTIONS}
                  selectedOption={replaceWithFields?.status as Option}
                  isMulti={false}
                  highlight={lockStatus}
                  disabled={lockStatus}
                  onChange={(value) => {
                    const newStatusFields = { ...replaceWithFields, status: value }
                    setReplaceWithFields(newStatusFields)
                    replaceWithCallback(newStatusFields)
                    lockFields(value, findFromFields, newStatusFields)
                  }}
                />
              </StatusDropdownContainer>
            </Col>
          </Row>
          <Row style={{ maxHeight: '33vh', overflowY: 'scroll' }}>
            <Col flex={4}>
              <Panel>
                {copyReplaceFields?.map(field => fetchCurrentFormElement(field, false, false))}
              </Panel>
            </Col>
            <Col />
            <Col flex={4}>
              <Panel>
                {copyReplaceFields?.map(field => fetchCurrentFormElement(field, false, true))}
              </Panel>
            </Col>
          </Row>
        </Col>
      </Row>}
    <Row>
      {addFields?.map(field => fetchCurrentFormElement(field, true, false))}
    </Row>
  </div>
}

export default BulkUpdateFindReplaceWidget
