import React, { useState } from 'react'
import { withContext } from '../../context/AppContext'
import { Col, Container, Content, Page, Section, Row, Title, Text, Avatar } from '@ix/ix-ui'
import styled from 'styled-components'
import SpudAvatar from '../../components/Avatar'
const UserPageStyledRow = styled(Row)`
  padding: 0.5em 0;
`

const UserPageSubheading = styled(Text)`
  font-weight: bold;
  font-size: 1.2rem;
`

const UserPageDivider = styled.div`
  background-color: #e0e0e0;
  height: 1px;
  margin: 1em 0;
`

const UserPageContainer = styled(Container)`
  background-color: #fff;
  padding: 1em 2em;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
`
const UserPageHeader = styled(Title)`
  padding-bottom: 0.7em;
  padding-left: 0.5em;
  line-height: 1.5;
  font-size: 2rem; 
`
const UserPageAvatar = styled(SpudAvatar)`
  width: 100px !important; 
  height: 100px; 
  display: block;
`
const UserAvatarWrapper = styled(Avatar)`
    width: 50px;
    height: 50px;
  `

const MainText = styled(Text)`
  color: ${props => props.theme.colors.grayDark}
`

const UserPage = () => {
  const [userData] = useState({
    id: 123,
    first_name: 'Test',
    last_name: 'User',
    email: 'tuser@infoxchange.org',
    role: 'Updater',
  })

  return (
    <Page>
      <Content>
        {/* Title Bar */}
        <UserPageContainer>
          <UserAvatarWrapper size="lg" round>
            <UserPageAvatar
              user={{
                first_name: userData.first_name,
                last_name: userData.last_name,
                id: userData.id.toString(),
              }}
            />
          </UserAvatarWrapper>
          <UserPageHeader level={1}>
            User Details
          </UserPageHeader>
        </UserPageContainer>

        {/* Main Content Section */}
        <Section style={{ background: '#fff', padding: '2em', marginBottom: '100em' }}>
          <Container style={{ background: '#fff' }}>
            <UserPageStyledRow>
              <Col>
                <p><UserPageSubheading >ID: &nbsp; </UserPageSubheading > <MainText>{userData.id}</MainText></p>
              </Col>
            </UserPageStyledRow>
            <UserPageDivider />
            <UserPageStyledRow>
              <Col>
                <p><UserPageSubheading >First Name: &nbsp; </UserPageSubheading >
                  <MainText>{userData.first_name}</MainText></p>
              </Col>
            </UserPageStyledRow>
            <UserPageDivider />
            <UserPageStyledRow>
              <Col>
                <p><UserPageSubheading >Last Name: &nbsp;</UserPageSubheading >
                  <MainText>{userData.last_name}</MainText> </p>
              </Col>
            </UserPageStyledRow>
            <UserPageDivider />
            <UserPageStyledRow>
              <Col>
                <p><UserPageSubheading >Email:  &nbsp;</UserPageSubheading > <MainText>{userData.email}</MainText></p>
              </Col>
            </UserPageStyledRow>
            <UserPageDivider />
            <UserPageStyledRow>
              <Col>
                <p><UserPageSubheading >Role:  &nbsp;</UserPageSubheading ><MainText>{userData.role}</MainText></p>
              </Col>
            </UserPageStyledRow>
          </Container>
        </Section>
      </Content>
    </Page>
  )
}

export default withContext(UserPage)
