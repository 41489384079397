import React, { useEffect, useState } from 'react'
import { Row, Col, Title } from '@ix/ix-ui'
import { SPUDInputField } from '../../../helpers/record'
import Select from 'react-select'
import { AdvancedFilterType, FilterValueType, SEARCH_OPERATOR_MAP, SearchOperators } from '../RecordFilter.service'
import { Option } from '../../../components/forms/AddEditRecord.type'

type TextInputWithSearchOperatorsProps<T> = {
  filterField: AdvancedFilterType<T>,
  onChangeCallback: (
    filterField: AdvancedFilterType<T>,
    filterValue: string,
    searchOperator?: SearchOperators
  ) => void,
  valueOverride?: {
    value: string,
    searchOperator: string
  }
}

function TextInputWithSearchOperators <T = FilterValueType> (
  {
    filterField,
    onChangeCallback,
    valueOverride,
  }: TextInputWithSearchOperatorsProps<T>) {
  const [options, setOptions] = useState<Array<{id: SearchOperators, name: string}>>([])
  const [searchOperator, setSearchOperator] = useState<Option>(
    {
      id: 'contains',
      name: SEARCH_OPERATOR_MAP.contains,
    })
  const [searchTerm, setSearchTerm] = useState('')

  const customStyle = {
    control: () => ({
      border: '1px solid #3a8ae8',
      backgroundColor: '#f6f6f6',
      borderRadius: 3,
      display: 'flex',
      padding: 1,
      marginTop: 1,
      textAlign: 'left',
    }),
    menuList: () => ({
      textAlign: 'left',
    }),
  }

  useEffect(() => {
    if (filterField.additionalControls) {
      const searchOperations: Array<{id: SearchOperators, name: string}> =
          filterField.additionalControls?.map(operator => ({
            id: operator,
            name: SEARCH_OPERATOR_MAP?.[operator],
          }))
      const foundOperator = searchOperations.find(option => option.id === valueOverride?.searchOperator)
      if (foundOperator) {
        setSearchOperator(foundOperator)
      }
      setOptions(searchOperations)
    }
  }, [])

  return (
    <Row>
      <Col xl={12} xxl={6}>
        <Row>
          <Col>
            <Title level={4} marginTop='4px' marginBottom='4px'>
              Modifier
            </Title>
          </Col>
        </Row>
        <Row>
          <Col>
            <Select
              // Ignoring because it's complaining that MenuList
              // is not a valid style option which it is
              // https://react-select.com/styles#the-unstyled-prop
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //  @ts-ignore
              styles={customStyle}
              id='spud-search-modifiers'
              options={options}
              value={searchOperator as Option}
              // The getOptionValue has to be a string
              getOptionValue={(option: Option) => !option?.id ? '' : option.id.toString()}
              isClearable={false}
              defaultValue={searchOperator as Option || null}
              getOptionLabel={(option: Option) => option.name }
              onChange={(option) => {
                setSearchOperator(option as Option)
                if (option?.id) {
                  onChangeCallback(filterField, searchTerm, option.id as SearchOperators)
                } else {
                  onChangeCallback(filterField, searchTerm)
                }
              }}
            />
          </Col>
        </Row>
      </Col>
      <Col xl={12} xxl={6} style={{ paddingTop: '4px' }}>
        <SPUDInputField
          label={filterField.label}
          name={filterField.name}
          type={filterField.type}
          value={valueOverride?.value || filterField.value}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchTerm(event.target.value)
            onChangeCallback(filterField, event.target.value, searchOperator.id as SearchOperators)
          }}
          fullwidth
        />
      </Col>
    </Row>
  )
}

export default TextInputWithSearchOperators
