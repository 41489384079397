import LinkedRecordsList from './LinkedRecordsList'
import { fetchLinkedRecordTableTitle } from '../RecordDetails.service'
import { SPUDSiteRecordDetails } from '../../../../@types/Site.type'
import SPUDComments from './Comments/SPUDComments'
import React, { Dispatch, ReactNode, SetStateAction } from 'react'
import styled from 'styled-components'
import { SPUDRecordWithData } from '../../../../@types/SPUDRecord.type'
import { SPUDOrganisationRecordDetails } from '../../../../@types/Organisation.type'
import { SPUDServiceRecordDetails } from '../../../../@types/Service.type'

const AddServiceButton = styled.button`
  background-color: #454545;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1em;
  border-radius: 5px;

  &:hover {
    background-color: #222;
  }
`

interface RightPanelProps {
  currentRightPanelTab: string;
  linkedListIsLoading: boolean;
  isLoading: boolean;
  linkedRecords: Array<{
    id: string | number | null,
    name: ReactNode,
    recordType: string,
    status: string,
    iss_id: number | null,
    referral_enabled: boolean,
    is_active: boolean | undefined,
    datasets?: Array<string>,
  }>; // Replace 'any' with the actual type of the linked records
  recordType: string;
  recordData: SPUDRecordWithData<SPUDOrganisationRecordDetails | SPUDSiteRecordDetails | SPUDServiceRecordDetails>;
  setRecordType: (type: string) => void;
  setIsNewRecord: (isNew: boolean) => void;
  setRecordData: (
    data: SPUDRecordWithData<SPUDOrganisationRecordDetails | SPUDSiteRecordDetails | SPUDServiceRecordDetails>
  ) => void; // Replace 'any' with the actual type of the record data
  recordId: string;
  setHasExternalComments: Dispatch<SetStateAction<boolean>>;
  disabled: boolean;
  navigateTo: (path: string) => void;
}

function RightPanel (
  {
    currentRightPanelTab,
    linkedListIsLoading,
    isLoading,
    linkedRecords,
    recordType,
    recordData,
    setRecordType,
    setIsNewRecord,
    setRecordData,
    recordId,
    setHasExternalComments,
    disabled,
    navigateTo,
  }: RightPanelProps) {
  switch (currentRightPanelTab) {
  case 'linkedRecords':
    return (
      <LinkedRecordsList
        loading={linkedListIsLoading || isLoading}
        title={fetchLinkedRecordTableTitle(recordType)}
        linkedRecords={linkedRecords}
        addNewComponent={recordType !== 'organisation'
          ? (
            <AddServiceButton
              disabled={linkedListIsLoading || disabled}
              onClick={() => {
                setRecordType('service')
                setIsNewRecord(true)
                let siteData = {
                  ...recordData.data as SPUDSiteRecordDetails,
                  id: recordData.id,
                  iss_id: recordData.iss_id,
                }
                if (recordType === 'service' && recordData.site) {
                  siteData = recordData.site
                }
                setRecordData({
                  ...recordData,
                  iss_id: null,
                  record_type: '',
                  update: {
                    id: null,
                    data: {},
                  },
                  id: null,
                  allocated_user: {
                    first_name: '',
                    last_name: '',
                    id: null,
                  },
                  revision: {
                    id: null,
                    status: '',
                    last_modified: null,
                  },
                  site: siteData,
                })
                navigateTo('/records/service/add')
              }}
            >
              {recordType === 'service' ? 'Add Another Service' : 'Add Service'}
            </AddServiceButton>
          )
          : null
        }
      />)
  case 'comments':
    return (
      <SPUDComments
        recordId={recordId}
        disabled={disabled}
        recordType={recordType}
        externalComment={false}
      />)
  case 'external_comments':
    return (
      <SPUDComments
        recordId={recordId}
        disabled={disabled}
        recordType={recordType}
        externalComment={true}
        setHasExternalComments={setHasExternalComments}
      />)
  default:
    return null
  }
}

export default RightPanel
