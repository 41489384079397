import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Col, Row, Title, Button, Alert } from '@ix/ix-ui'
import { spudAPI } from '../../services/spud.service'
import { BulkAutoUpdateRecord, BulkUpdateContextType } from './BulkUpdateContext.type'
import {
  BulkUpdateFlowStateType,
  FieldArray,
  changes,
  extractComments,
  flattenArray,
  haveNoChangesBeenMade,
  markDifferences,
  flattenNewLocationObj,
} from './BulkAutoUpdateService'
import { SPUDSiteRecordDetails } from '../../../@types/Site.type'
import { SPUDServiceRecordDetails } from '../../../@types/Service.type'
import { isValidServiceProviderPhoneNumber, SPUDInputField } from '../../helpers/record'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { SPUDOrganisationRecordDetails } from '../../../@types/Organisation.type'
import { Toaster } from 'react-hot-toast'
import ReCAPTCHA from 'react-google-recaptcha'
import { getCaptchaSiteKey } from '../../helpers/api'

const ReviewFormContainer = styled.div`
  width: 70%;
`

const ReviewTable = styled.table`
  border-spacing: 0;
`

const ReviewTableHeadingCell = styled.th`
  padding: 1em;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
`

const ReviewTableRowCell = styled.td`
  padding: 1em;
  border: 1px solid #ddd;
  text-align: start;
`

const ReviewTableCellFieldChanges = styled.div`
  font-size: 1.3em;
`

const CatpchaContainer = styled.div`
  margin: 1em 0;
`

const LinkButton = styled(Button)`
  border: none;
  background: None;
  text-decoration: underline;
  padding: 0;
  :hover {
    padding: 0;
    text-decoration: none;
    border: none;
    background: none;
  };
  :focus {
    border: none;
    background: none;
    box-shadow: none;
  }
`

type ReviewFormProps = {
  setBulkAutoUpdateFlowState: (flowState: BulkUpdateFlowStateType) => void
} & BulkUpdateContextType

function ReviewForm (
  {
    site,
    services,
    setBulkAutoUpdateFlowState,
    organisation,
    setActiveServiceIndexContext,
    clearState,
    contactInfo,
    saveContactInfo,
  }: ReviewFormProps) {
  const [recordsToReview, setRecordsToReview] = useState<
    Array<BulkAutoUpdateRecord<SPUDSiteRecordDetails | SPUDServiceRecordDetails | SPUDOrganisationRecordDetails>>
  >([])
  const [allRecordsConfirmed, setAllRecordsConfirmed] = useState(false)
  const [captchaCode, setCaptchaCode] = useState<string | null>(null)
  const [validContactInfo, setValidContactInfo] = useState<
  {
    validName: {
      valid: boolean,
      fieldTouched: boolean
    },
    validNumber:{
      valid: boolean,
      fieldTouched: boolean
    },
    validEmail: {
      valid: boolean,
      fieldTouched: boolean
    },
    validCaptcha: {
      valid: boolean,
      fieldTouched: boolean
    },
    }>({
      validName: {
        valid: false,
        fieldTouched: false,
      },
      validNumber: {
        valid: false,
        fieldTouched: false,
      },
      validEmail: {
        valid: false,
        fieldTouched: false,
      },
      validCaptcha: {
        valid: false,
        fieldTouched: false,
      },
    })

  useEffect(() => {
    const records = [
      organisation,
      site,
      ...services,
    ]
    const confirmedRecords = records.flatMap(rec => rec.confirmed)
    setAllRecordsConfirmed(confirmedRecords.every(confirmedRecord => confirmedRecord))
    setRecordsToReview(
      records as Array<
        BulkAutoUpdateRecord<SPUDSiteRecordDetails | SPUDServiceRecordDetails | SPUDOrganisationRecordDetails>
      >)
  }, [site.currentData, services])

  const noChanges = <Title level={4} marginTop='0'>
    No changes required
  </Title>

  const buildChanges = (
    record: BulkAutoUpdateRecord<SPUDSiteRecordDetails | SPUDServiceRecordDetails | SPUDOrganisationRecordDetails>) => {
    let recordChanges = {}
    const changedFields = changes(record)
    changedFields.forEach(changedField => {
      recordChanges = {
        ...recordChanges,
        [changedField]: record.currentData?.update?.data?.[changedField],
      }
    })
    if (record.recordType === 'service' && !record.currentData?.id) {
      recordChanges = record.currentData.update.data
    }
    return recordChanges
  }

  const updateRecord = async () => {
    const recordsToUpdate: Array<{
      id: number | null,
      data: {
        changes: {[x: string]: unknown},
        contactInfo: {[x: string]: unknown},
        comment?: string,
        deleted?: boolean,
      },
      recordType: string
    }> = []

    recordsToReview.forEach(
      record => changes(record).length &&
      recordsToUpdate.push({
        id: record.currentData?.id || 0,
        data: {
          changes: buildChanges(record),
          contactInfo: contactInfo,
          comment: record.currentData.update?.data?.bulk_update_comment,
          deleted: !!record.currentData.update?.data?.bulk_update_delete,
        },
        recordType: record.currentData.record_type,
      }),
    )
    if (recordsToUpdate.length === 0) {
      recordsToReview.forEach(
        record => recordsToUpdate.push(extractComments(record, contactInfo)),
      )
    }
    if (sessionStorage.getItem('bulk_update_code')) {
      for (const recordToUpdate of recordsToUpdate) {
        await spudAPI.bulkUpdateSave(
          sessionStorage.getItem('bulk_update_code'),
          recordToUpdate.id,
          recordToUpdate.data,
        )
      }
      clearState()
    }
    setBulkAutoUpdateFlowState('final')
  }

  const validContactInformation = () => {
    return contactInfo.contact_email && contactInfo.contact_name && contactInfo.contact_number && captchaCode
  }

  const goToRecord = (
    record: BulkAutoUpdateRecord<SPUDSiteRecordDetails | SPUDServiceRecordDetails | SPUDOrganisationRecordDetails>,
  ) => {
    if (record.recordType === 'site' || record.recordType === 'organisation') {
      setBulkAutoUpdateFlowState('site')
    } else {
      const serviceIndex = services.findIndex(
        service => service.currentData === record.currentData)
      setActiveServiceIndexContext(serviceIndex)
      setBulkAutoUpdateFlowState('service')
    }
  }

  const FieldChanges = (
    { field, record }: {
      field: keyof SPUDSiteRecordDetails & keyof SPUDServiceRecordDetails & keyof Location,
      record: BulkAutoUpdateRecord<SPUDSiteRecordDetails | SPUDServiceRecordDetails | SPUDOrganisationRecordDetails>
    }) => {
    const originalValues: unknown = record.originalData?.update?.data?.[field] || record.originalData?.[field]
    const newValues: unknown = record.currentData?.update?.data?.[field]
    if (field === 'bulk_update_comment') {
      return null
    }
    if (typeof newValues === 'object') {
      if (Array.isArray(newValues)) {
        const flattenArrayObj = flattenArray(
          newValues as FieldArray,
          originalValues as FieldArray | [] && Array.isArray(originalValues) ? originalValues as FieldArray : [],
          field,
        )
        return <ReviewTableCellFieldChanges>
          {flattenArrayObj.element}
        </ReviewTableCellFieldChanges>
      } else if (field === 'location') {
        const formattedValues = flattenNewLocationObj(newValues as {
          building_name: string,
          confidential: boolean,
          flat_unit: string,
          floor_level: string,
          postcode: string,
          state: string,
          street_number: string,
          street_name: string,
          street_suffix: string,
          street_type: string,
          suburb: string,
          geo_point: {
            lat: string,
            lon: string
          },
        }, originalValues as {
          building_name: string,
          confidential: boolean,
          flat_unit: string,
          floor_level: string,
          postcode: string,
          state: string,
          street_number: string,
          street_name: string,
          street_suffix: string,
          street_type: string,
          suburb: string,
          geo_point: {
            lat: string,
            lon: string
          },
        })
        return <ReviewTableCellFieldChanges>
          <div>
            <strong>{field}</strong>: {formattedValues.map((word, index) => (
              <span key={index}>{word} </span>
            ))}
          </div>
        </ReviewTableCellFieldChanges>
      } else {
        return noChanges
      }
    }
    const splitOriginalValues: string = originalValues as string || ''
    const splitNewValues: string = newValues as string || ''
    if (splitNewValues && typeof splitNewValues === 'string') {
      const formattedValues = markDifferences(
        field === 'web'
          ? splitOriginalValues.split('')
          : splitOriginalValues.split(' '),
        field === 'web'
          ? splitNewValues.split('')
          : splitNewValues.split(' '),
        field === 'web',
      )
      return <ReviewTableCellFieldChanges>
        <div>
          <strong>{field}</strong>: <div>
            {field !== 'web' && typeof formattedValues !== 'string'
              ? formattedValues.map((word, index) => (
                <span key={index}>{word} </span>
              ))
              : <div dangerouslySetInnerHTML={{ __html: formattedValues }}/>}
          </div>
        </div>
      </ReviewTableCellFieldChanges>
    } else if (field === 'opening_hours_simple' && originalValues === undefined) {
      return noChanges
    }
    return noChanges
  }

  const RecordChanges = (
    { record }: {
      record: BulkAutoUpdateRecord<SPUDSiteRecordDetails | SPUDServiceRecordDetails | SPUDOrganisationRecordDetails>
    }) => {
    const recordChanges = changes(record)
    if (haveNoChangesBeenMade(record, ['bulk_update_comment'])) {
      return noChanges
    } else if (recordChanges.length) {
      return (
        <div>
          {recordChanges.map((field: keyof SPUDSiteRecordDetails
          & keyof SPUDServiceRecordDetails & keyof Location, index) => (
            <div key={`${record.currentData.id}-${index}`} style={{ margin: '1em 0' }}>
              <FieldChanges field={field} record={record}/>
            </div>
          ))}
        </div>
      )
    }
    return noChanges
  }
  const onVerifyCaptcha = (token: string | null) => {
    setCaptchaCode(token)
    setValidContactInfo({
      ...validContactInfo,
      validCaptcha: {
        valid: !!token,
        fieldTouched: true,
      },
    })
  }

  const CelContent = ({ record }: {
    record: BulkAutoUpdateRecord<SPUDSiteRecordDetails | SPUDServiceRecordDetails | SPUDOrganisationRecordDetails>
  }) => (
    !record.confirmed
      ? <div>
        <Row>
          <Col>
            <Title level={4} marginTop='0'>
              Not reviewed
            </Title>
          </Col>
        </Row>
        <Row>
          Please click on the site/service link in the left column and check{' '}
          there are no changes required. If no changes are required, please{' '}
          tick the &ldquoAll information on file is correct...&ldquo check box at the bottom of the form.
        </Row>
      </div>
      : <div>
        <RecordChanges record={record}/>
      </div>
  )

  return <ReviewFormContainer>
    <Row style={{ padding: '1em' }}>
      <Col>
        <Title>
          Review & submit
        </Title>
      </Col>
    </Row>
    <Row style={{ padding: '1em 0' }}>
      <Col>
        <hr style={{ width: '100%' }}/>
      </Col>
    </Row>
    {!allRecordsConfirmed &&
      <Row style={{ padding: '1em 0' }}>
        <Col>
          <Alert type='error'>
            You have not reviewed this form yet. Please carefully check that the information on each form{' '}
            is correct and perform any necessary changes. If no changes are required,{' '}
            please confirm this by turning on the &apos;`All information on file is correct&apos;` acknowledgement{' '}
            located at the bottom of each page
          </Alert>
        </Col>
      </Row>
    }
    <Row>
      <Col>
        <ReviewTable>
          <thead>
            <tr>
              <ReviewTableHeadingCell>
                <Title level={4} marginTop='0'>
                  Organisation / Site / Service Form
                </Title>
              </ReviewTableHeadingCell>
              <ReviewTableHeadingCell>
                <Title level={4} marginTop='0'>
                  Fields Updated
                </Title>
              </ReviewTableHeadingCell>
            </tr>
          </thead>
          <tbody>
            {recordsToReview.map((recordToReview, index) =>
              <tr key={`review-${index}`}>
                <ReviewTableRowCell>
                  <LinkButton onClick={() => goToRecord(recordToReview)}>
                    {recordToReview.currentData.update.data.name}
                  </LinkButton>
                  <br/>
                  {recordToReview.recordType === 'site' && '(site)'}
                  {recordToReview.recordType === 'organisation' && '(organisation)'}
                </ReviewTableRowCell>
                <ReviewTableRowCell>
                  <CelContent record={recordToReview}/>
                </ReviewTableRowCell>
              </tr>)}
          </tbody>
        </ReviewTable>
      </Col>
    </Row>
    <Row style={{ padding: '1em 0' }}>
      <Col>
        <hr style={{ width: '100%' }}/>
      </Col>
    </Row>
    <Row>
      <Col>
        <SPUDInputField
          label={<Title level={4} marginTop='5px'>Contact name</Title>}
          required={true}
          fullwidth={true}
          value={contactInfo.contact_name}
          name='contact_name'
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setValidContactInfo({
              ...validContactInfo,
              validName: {
                valid: !!event.target.value,
                fieldTouched: true,
              },
            })
            saveContactInfo({ ...contactInfo, contact_name: event.target.value })
          }}
        />
        {(!validContactInfo.validName.valid && validContactInfo.validName.fieldTouched) && <Alert type='error'>
          Name is required
        </Alert>}
      </Col>
    </Row>
    <Row>
      <Col>
        <SPUDInputField
          label={<Title level={4} marginTop='5px'>Contact phone</Title>}
          required={true}
          fullwidth={true}
          placeholder='3, 6, 7 or 10 digits'
          value={contactInfo.contact_number}
          name='contact_number'
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setValidContactInfo({
              ...validContactInfo,
              validNumber: {
                valid: isValidServiceProviderPhoneNumber(event.target.value) === true,
                fieldTouched: true,
              },
            })
            saveContactInfo({ ...contactInfo, contact_number: event.target.value })
          }}
        />
        {!validContactInfo.validNumber.valid && validContactInfo.validNumber.fieldTouched && <Alert type='error'>
          Number is invalid
        </Alert>}
      </Col>
    </Row>
    <Row>
      <Col>
        <SPUDInputField
          label={<Title level={4} marginTop='5px'>Contact email</Title>}
          required={true}
          fullwidth={true}
          value={contactInfo.contact_email}
          name='contact_email'
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setValidContactInfo({
              ...validContactInfo,
              validEmail: {
                // eslint-disable-next-line max-len
                valid: !!event.target.value.match(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i),
                fieldTouched: true,
              },
            })
            saveContactInfo({ ...contactInfo, contact_email: event.target.value })
          }}
        />
        {!validContactInfo.validEmail.valid && validContactInfo.validEmail.fieldTouched && <Alert type='error'>
          Email is invalid
        </Alert>}
      </Col>
    </Row>
    <Row>
      <Col>
        <CatpchaContainer>
          <ReCAPTCHA
            size="normal"
            onChange={onVerifyCaptcha}
            sitekey={getCaptchaSiteKey()}
          />
        </CatpchaContainer>
      </Col>
      {!validContactInfo.validCaptcha.valid && validContactInfo.validCaptcha.fieldTouched && <Alert type='error'>
        Please verify the CAPTCHA
      </Alert>}
    </Row>
    <Row style={{ padding: '1em 0' }}>
      <Col>
        <hr style={{ width: '100%' }}/>
      </Col>
    </Row>
    <Row>
      <Col>
        <Alert type='info'>
          By submitting my changes, I am taking responsibility for the accurate recording{' '}
          of information about my organisation / site / service.
        </Alert>
      </Col>
    </Row>
    <Row>
      <Col direction='row' justify='space-between'>
        <Button onClick={() => setBulkAutoUpdateFlowState('service')}>
          <FontAwesomeIcon icon={faArrowLeft as IconProp}/> Update services
        </Button>
        <Button
          onClick={() => updateRecord()}
          active={allRecordsConfirmed && validContactInformation()}
          disabled={!(allRecordsConfirmed && validContactInformation())}
        >
          Submit changes <FontAwesomeIcon icon={faArrowRight as IconProp}/>
        </Button>
      </Col>
    </Row>
    <Toaster />
  </ReviewFormContainer>
}

export default ReviewForm
