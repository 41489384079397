import { AxiosPromise } from 'axios'
import API, { getSPUDAPIUrl } from '../helpers/api'
import { SPUDRecord, SPUDRecordWithData, SPUDRecordWithUpdate } from '../../@types/SPUDRecord.type'
import { SPUDSiteRecordDetails } from '../../@types/Site.type'
import { SPUDOrganisationRecordDetails } from '../../@types/Organisation.type'
import { SPUDServiceRecordDetails } from '../../@types/Service.type'
import { SPUDRecordFilterListType } from '../../@types/FilterTypes.type'
import { AdvancedFilterType } from '../pages/RecordFilter/RecordFilter.service'

export type transactionParams = {
    filter?: string | Array<string> | AdvancedFilterType,
    recordType: string | null,
    parentId?: number | null,
    limit?: number,
    offset?: number,
    ordering?: string,
    details?: Record<string, string>,
    isBulkUpdate?: boolean,
    filters?: object
    count?: boolean,
    accessId?: string | null,
  }

export type transactionResultType = {
  results: Array<SPUDRecord<SPUDSiteRecordDetails | SPUDOrganisationRecordDetails | SPUDServiceRecordDetails>>,
  next: string | null,
  count: number
}

export type transactionFilterResultType = {
  results: Array<SPUDRecordFilterListType>,
  next: string | null,
  count: number
}

export type SPUDQuickSearchResponseType = {
    id: number,
    iss_id: number,
    name: string,
    site: string,
    suburb: string,
    record_type: string,
}

const countRecords = (
  params: transactionParams,
  filterParams: URLSearchParams | string | {[x: string]: unknown} | null,
  abortController: AbortController,
): Promise<{
  data: {
    count: number
  }
}> => {
  let searchParam = {
    record_type: params.recordType,
    limit: params.limit,
    offset: params.offset || 0,
    ordering: params.ordering,
    search_term: JSON.stringify(params.details) || '',
  }
  if (typeof filterParams !== 'string') {
    const filters = filterParams as URLSearchParams
    if (filters) {
      for (const [key, value] of filters.entries()) {
        searchParam = { ...searchParam, [key]: value }
      }
    }
  } else {
    searchParam = { ...searchParam, [filterParams]: true }
  }
  return API
    .get(`${getSPUDAPIUrl()}records/${params.recordType}/count/`, {
      params: searchParam,
      signal: abortController.signal,
    })
}
const recordList = (
  params: transactionParams,
  filterParams: URLSearchParams | string | {[x: string]: unknown} | null,
  abortController: AbortController,
): AxiosPromise<{
    results: Array<SPUDRecord<SPUDSiteRecordDetails | SPUDOrganisationRecordDetails | SPUDServiceRecordDetails>>,
    next: string | null,
    count: number,
}> => {
  let searchParam = {
    record_type: params.recordType,
    limit: params.limit,
    offset: params.offset || 0,
    ordering: params.ordering,
    search_term: JSON.stringify(params.details) || '',
  }
  if (filterParams) {
    if (typeof filterParams !== 'string') {
      const filters = filterParams as URLSearchParams
      for (const [key, value] of filters.entries()) {
        searchParam = { ...searchParam, [key]: value }
      }
    } else {
      searchParam = { ...searchParam, [filterParams]: true }
    }
  }
  return API
    .get(`${getSPUDAPIUrl()}records/`, {
      params: searchParam,
      signal: abortController.signal,
    })
}

function recordDetail (props: {
  id: number | string
  recordType: string | undefined,
  isBulkUpdate?: boolean,
}): AxiosPromise<SPUDRecordWithUpdate<SPUDSiteRecordDetails | SPUDOrganisationRecordDetails| SPUDServiceRecordDetails>|
SPUDRecordWithData<SPUDSiteRecordDetails | SPUDOrganisationRecordDetails | SPUDServiceRecordDetails>> {
  return API
    .get(`${getSPUDAPIUrl()}${props.isBulkUpdate ? 'bulk-update' : 'record'}/${props.recordType}/${props.id}/`)
}

function revisionUpdates (props: {
    id: number
}): AxiosPromise {
  return API
    .get(`${getSPUDAPIUrl()}revisions/${props.id}/updates/`)
}

function linkedRecords ({ recordId, linkedRecordType, isBulkUpdate }: {
  recordId: number | null | undefined | string,
  linkedRecordType: string,
  isBulkUpdate?: boolean
}): AxiosPromise<{
    results: Array<SPUDRecord<SPUDSiteRecordDetails | SPUDOrganisationRecordDetails | SPUDServiceRecordDetails>>
}> {
  return API
    .get(`${getSPUDAPIUrl()}${isBulkUpdate ? 'bulk-update/linked' : 'record'}/${recordId}/${linkedRecordType}/`)
}

export interface CheckRecordActionResponseType {
  copy: boolean,
  delete: boolean,
  restore: boolean,
  eReferral: boolean,
  active_iss_entities: Array<{
    id: string,
    name: string
  }>
}

const checkRecordActions = ({ recordId, recordType }: {
  recordId: number |null | string | undefined,
  recordType: string | undefined,
}): AxiosPromise<CheckRecordActionResponseType> => {
  return API
    .get(`${getSPUDAPIUrl()}records/${recordType}/${recordId}/check_operations/`)
}

const searchRecords = (
  searchTerm: string,
  params: {
    seeMore: boolean,
    limit: number,
    offset: number
  },
): AxiosPromise<{
    results: {
      [x: string]: Array<SPUDQuickSearchResponseType>,
    }
    next: string | null,
    count: number,
}> => {
  const searchParam = {
    limit: params.limit,
    offset: params.offset,
    ordering: 'id',
    search_term: JSON.stringify({ name: searchTerm }) || '',
    see_more: params.seeMore,
  }
  return API
    .get(`${getSPUDAPIUrl()}records/search/`, {
      params: searchParam,
    })
}

export const transactionService = {
  recordList,
  countRecords,
  revisionUpdates,
  linkedRecords,
  recordDetail,
  checkRecordActions,
  searchRecords,
}
