import React, { useEffect, useState } from 'react'
import { Alert, Button, Col, Row, Title } from '@ix/ix-ui'
import { Controller, ControllerRenderProps, SubmitHandler, useForm, FieldErrors } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { SPUDInputField, SPUDToggle } from '../../../helpers/record'
import {
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons'
import SPUDAutoComplete from '../../../components/General/SPUDAutoComplete'
import SPUDDatePicker from '../../../components/General/SPUDDatePicker'
import { Option } from '../../../components/forms/AddEditRecord.type'
import toast, { Toaster } from 'react-hot-toast'
import SPUDToastNotification from '../../../components/General/SPUDToastNotification'
import { isAfter, format } from 'date-fns'
import { spudAPI } from '../../../services/spud.service'
import { loadTemplates } from '../../../services/notification.service'
import { getSPUDBulkUpdateEnvironment } from '../../../helpers/NotificationAPI'
import SPUDTextArea from '../../../components/General/SPUDTextArea'
import FilterChips from '../../RecordFilter/FilterChips'
import { AdvancedFilterType, FilterValueType } from '../../RecordFilter/RecordFilter.service'
import { filterToUrlParams, urlParamsToEmailFilterObj } from '../../RecordsPage/ListView/ListView.service'

type EmailCampaignFormData = {
  state: Option | null,
  fromDate: Date,
  toDate: Date,
  num_sites: number | null,
  max_services: number | null,
  date: Date | null,
  template: Option | null,
  email_content: string | null
  email_summary: boolean
}

type formProps = {
  filterValue: {[p: string]: AdvancedFilterType<FilterValueType>};
  onClearFilter: () => void;
  openAdvancedFilters: boolean;
  onOpenAdvancedFilters: (openFilter: boolean, editingFilter?: string) => void
}

const STATE_OPTIONS = [
  { id: 'ACT', name: 'ACT' },
  { id: 'NSW', name: 'NSW' },
  { id: 'NT', name: 'NT' },
  { id: 'QLD', name: 'QLD' },
  { id: 'SA', name: 'SA' },
  { id: 'TAS', name: 'TAS' },
  { id: 'VIC', name: 'VIC' },
  { id: 'WA', name: 'WA' },
]

const FieldDesc = styled.div`
  padding: 0.3em 0 2em;
  color: #666;
`

const FormContainer = styled.div<{testMode: boolean}>`
  border: ${props => props.testMode ? '3px solid orange' : 'none'};
  padding: 1em;
`

const TestModeWarning = styled.div`
  background-color: orange;
  border-radius: 3px;
  padding: 1em;
`

const customStyle = {
  control: () => ({
    border: '1px solid #eee',
    borderRadius: 3,
    display: 'flex',
    padding: 0,
    marginTop: '-5px',
    textAlign: 'left',
    width: '125px',
    background: '#eee',
  }),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  placeholder: (provided: any) => ({
    ...provided,
    color: '#555',
  }),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  menu: (provided: any) => ({
    ...provided,
    width: '125px',
  }),
}

const FieldState = styled(Col)`
  margin: 1.5em 0 3em;
  label {
    display: none;
  }
`

const StyledButton = styled(Button)`
  margin-bottom: 3em;
  &.disabled-button {
    cursor: not-allowed;
    &:hover {
      background: #E0E6EE;
    }
  }
`
const ArrowColumn = styled(Col)`
  padding-top: 27px;
  display: none;
  @media (min-width: 1400px) {
    display: inline;
  }
`
const FromDateColumn = styled(Col)`
  max-width: 215px;
  min-width: 215px;
`

const TestSettings = styled.div`
  border: 2px solid black;
  border-radius: 3px;
  padding: 1em;
`

// Allow only numbers
const patternRegex = /^[0-9]+$/

function EmailCampaignForm ({ filterValue, onClearFilter, onOpenAdvancedFilters, openAdvancedFilters }: formProps) {
  const methods = useForm<EmailCampaignFormData>({
    mode: 'all',
  })

  const [templates, setTemplates] = useState([])
  const [testSettings, setTestSettings] = useState({
    sites_updated: 0,
  })
  const [testMode, setTestMode] = useState(false)
  const [checkSettings, setCheckSettings] = useState(false)
  const [checkSettingsLoading, setCheckSettingsLoading] = useState(false)

  useEffect(() => {
    const fetchTemplates = async () => {
      const response = await loadTemplates()
      setTemplates(response)
    }
    fetchTemplates()
  }, [])

  // Set the default FromDate value to the first date of six months ago
  const defaultFromDate = new Date()
  defaultFromDate.setMonth(defaultFromDate.getMonth() - 6)
  defaultFromDate.setDate(1)

  // Set the default ToDate value to the last date of six months ago
  const defaultToDate = new Date()
  defaultToDate.setMonth(defaultToDate.getMonth() - 6)
  defaultToDate.setDate(new Date(defaultToDate.getFullYear(), defaultToDate.getMonth() + 1, 0).getDate())

  const validateRange = (fromDate: Date | null, toDate: Date | null): boolean => {
    let validRange = true
    if (
      fromDate && toDate
    ) {
      validRange = isAfter(toDate, fromDate)
    }
    return validRange
  }

  const validateDate = (value: Date | null): boolean => {
    if (value && (value > new Date())) {
      return false
    }
    return true
  }

  const renderError = (errorLabel: string, formField: string) => {
    const { formState: { errors } } = methods
    const error = errors?.[formField as keyof FieldErrors<EmailCampaignFormData>]

    if (error) {
      let errorMessage = ''

      if (error.type === 'required') {
        errorMessage = `${errorLabel} is required.`
      } else if (error.type === 'min') {
        errorMessage = 'Please select a value that is no less than 0.'
      } else if (error.type === 'pattern') {
        errorMessage = error.message || ''
      } else if (error.type === 'dateNotFuture') {
        errorMessage = 'Future dates not allowed'
      }

      if (errorMessage) {
        return (
          <Alert type="error" style={{ marginTop: -15, marginBottom: 20 }}>
            {errorMessage}
          </Alert>
        )
      }
    }

    return null
  }

  const onSaveBulkUpdateConfig: SubmitHandler<EmailCampaignFormData> = async (data: EmailCampaignFormData) => {
    try {
      const formattedFromDate = format(data.fromDate, 'yyyy-MM-dd')
      const formattedToDate = format(data.toDate, 'yyyy-MM-dd')
      let filterParamsObject = null
      if (filterValue) {
        const params = new URLSearchParams()
        const processedFilterParams = filterToUrlParams(filterValue, params)
        filterParamsObject = urlParamsToEmailFilterObj(processedFilterParams)
      }
      const apiData = {
        state: data.state,
        last_updated_date_from: formattedFromDate,
        last_updated_date_to: formattedToDate,
        num_sites: data.num_sites,
        max_services: data.max_services,
        filters: JSON.stringify(filterParamsObject),
        template: data.template,
        email_environment: getSPUDBulkUpdateEnvironment(),
        email_content: data.email_content,
        email_summary: data.email_summary,
        test_mode: testMode,
      }
      await spudAPI.saveBulkUpdateConfig(apiData)
      methods.reset()
      onClearFilter()
      methods.setValue('email_content', '')
      setCheckSettings(false)
      setTestMode(false)
      toast.custom(
        <SPUDToastNotification
          title="Success"
          message={
            <span>
              Email campaign has been created
            </span>
          }
          success
        />,
        {
          position: 'bottom-right',
        },
      )
    } catch (error) {
      console.error('Error occurred:', error)
    }
  }

  return (
    <form
      onSubmit={methods.handleSubmit(onSaveBulkUpdateConfig)}
    >
      <FormContainer testMode={testMode}>
        <Row>
          <Col flex={2}>
            <Title level={2}>New email campaign</Title>
          </Col>
          {testMode && <Col align='center'>
            <TestModeWarning>
              Test mode enabled: no emails will be sent to site emails
            </TestModeWarning>
          </Col>}
          <Col direction='row' flex={2} justify='flex-end'>
            <SPUDToggle
              padding='0'
              label='Test Mode'
              checked={testMode}
              handleChange={(checked: boolean) =>
                setTestMode(checked)
              }
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Row>
              <FieldState lg={6} md={4} className='form-state'>
                <Controller
                  name='state'
                  control={methods.control}
                  defaultValue={null}
                  rules={{ required: true }}
                  render={({ field }) =>
                    <SPUDAutoComplete
                      label="Select State"
                      customStyleOverride={customStyle}
                      options={STATE_OPTIONS}
                      isMulti={false}
                      disabled={false}
                      selectedOption={methods.getValues('state') as Option | null}
                      placeholder="State"
                      {...field}
                    />}
                />
                {methods.formState.errors?.state &&
                <Alert type="error">
                  Please select state
                </Alert>
                }
              </FieldState>
            </Row>
            <Row>
              <FromDateColumn lg={5} md={12}>
                <Controller
                  name='fromDate'
                  control={methods.control}
                  defaultValue={defaultFromDate}
                  rules={{
                    required: true,
                    validate: {
                      dateNotFuture: validateDate,
                      dateRange: (value) => validateRange(value, methods.getValues('toDate')),
                    },
                  }}
                  render={({ field }) =>
                    <Row>
                      <Col style={{ padding: 0 }} align='baseline'>
                        <div style={{ marginRight: '1em' }}>
                          <Title level={4} marginTop='0px'>Site Last Updated from</Title>
                        </div>
                        <SPUDDatePicker
                          formElement={{
                            name: 'fromDate',
                            label: 'Site Last Updated from',
                            type: 'date',
                            rules: { required: true },
                            defaultValue: defaultFromDate,
                            maxDate: new Date(),
                            dateFormat: 'dd/MM/yyyy',
                            allowAcceptServiceProviderChange: false,
                          }}
                          fullWidth={false}
                          field={field as unknown as ControllerRenderProps}
                          disabled={false}
                          trigger={(value:string) => methods.trigger(value as 'fromDate')}
                        />
                        <FieldDesc>dd/mm/yyyy</FieldDesc>
                      </Col>
                    </Row>
                  }
                />
                {renderError('Date', 'fromDate')}
              </FromDateColumn>
              <ArrowColumn md={1} >
                <FontAwesomeIcon icon={faArrowRight as IconProp} size='2x'/>
              </ArrowColumn>
              <Col lg={6} md={12} style={{ minWidth: 220 }}>
                <Controller
                  name='toDate'
                  control={methods.control}
                  defaultValue={defaultToDate}
                  rules={{
                    required: true,
                    validate: {
                      dateNotFuture: validateDate,
                      dateRange: (value) => validateRange(methods.getValues('fromDate'), value),
                    },
                  }}
                  render={({ field }) =>
                    <Row>
                      <Col style={{ padding: 0 }} align='baseline'>
                        <div style={{ marginRight: '1em' }}>
                          <Title level={4} marginTop='0px'>Site Last Updated to</Title>
                        </div>
                        <SPUDDatePicker
                          formElement={{
                            name: 'toDate',
                            label: 'Site Last Updated to',
                            type: 'date',
                            rules: { required: true },
                            defaultValue: defaultToDate,
                            maxDate: new Date(),
                            dateFormat: 'dd/MM/yyyy',
                            allowAcceptServiceProviderChange: false,
                          }}
                          fullWidth={false}
                          field={field as unknown as ControllerRenderProps}
                          disabled={false}
                          trigger={(value:string) => methods.trigger(value as 'toDate')}
                        />
                        <FieldDesc>dd/mm/yyyy</FieldDesc>
                      </Col>
                    </Row>
                  }
                />
                {renderError('Date', 'toDate')}
              </Col>
            </Row>

            {!validateRange(
              methods.getValues('fromDate'),
              methods.getValues('toDate'),
            ) && <Row style={{ marginTop: -20 }}>
              <Col>
                <Alert type="error">
                  Date range is incorrect
                </Alert>
              </Col>
            </Row>}

            <Row>
              <Col>
                <Controller
                  name='num_sites'
                  control={methods.control}
                  defaultValue={20}
                  rules={{
                    required: true,
                    min: 0,
                    pattern: {
                      value: patternRegex,
                      message: 'Please enter a valid number.',
                    },
                  }}
                  render={({ field }) =>
                    <SPUDInputField
                      type="number"
                      label='Number of Sites'
                      required={true}
                      min={0}
                      {...field}
                    />}
                />
                <FieldDesc>Number of sites with matching email</FieldDesc>
                {renderError('Number of Sites', 'num_sites')}
              </Col>
            </Row>
            <Row>
              <Col>
                <Controller
                  name='max_services'
                  control={methods.control}
                  defaultValue={20}
                  rules={{
                    required: true,
                    min: 0,
                    pattern: {
                      value: patternRegex,
                      message: 'Please enter a valid number.',
                    },
                  }}
                  render={({ field }) =>
                    <SPUDInputField
                      type="number"
                      label='Max Number of Services'
                      required={true}
                      min={0}
                      {...field}
                    />}
                />
                <FieldDesc>Max number of services with per site</FieldDesc>
                {renderError('Max Number of Services', 'max_services')}
              </Col>
            </Row>
            <Row>
              <Col>
                <div>
                  <h3>Filters</h3>
                  {filterValue &&
                    <FilterChips
                      activeFilters={filterValue}
                      onChipClick={(chip) => {
                        chip ? onOpenAdvancedFilters?.(true, chip) : onOpenAdvancedFilters?.(false, '')
                      }}
                      filterPanelOpen={openAdvancedFilters}
                    />}
                  <TestSettings>
                    {!checkSettings && 'Click test settings to get a count of ' +
                      'how many emails will be sent, and how many records to be updated'}
                    {checkSettings && !checkSettingsLoading && <div>
                      <strong>{testSettings.sites_updated}</strong> sites to be updated
                    </div>}
                    {checkSettingsLoading && 'loading...'}
                  </TestSettings>
                  <Button
                    active
                    loading={checkSettingsLoading}
                    onClick={async () => {
                      let filterParamsObject = null
                      if (filterValue) {
                        const params = new URLSearchParams()
                        const processedFilterParams = filterToUrlParams(filterValue, params)
                        filterParamsObject = urlParamsToEmailFilterObj(processedFilterParams)
                      }
                      setCheckSettings(true)
                      setCheckSettingsLoading(true)
                      const response = await spudAPI.checkFilters({
                        state: methods.getValues('state'),
                        last_updated_date_from: format(methods.getValues('fromDate'), 'yyyy-MM-dd'),
                        last_updated_date_to: format(methods.getValues('toDate'), 'yyyy-MM-dd'),
                        num_sites: methods.getValues('num_sites'),
                        max_services: methods.getValues('max_services'),
                        filters: filterParamsObject,
                      })
                      setCheckSettingsLoading(false)
                      setTestSettings(response.data as {
                          sites_updated: number
                        })
                    }}
                  >
                      Test settings
                  </Button>
                </div>
              </Col>
            </Row>
            <Toaster />

          </Col>
          <Col>
            <Row>
              <Col style={{ marginTop: 4 }}>
                <Controller
                  name='template'
                  control={methods.control}
                  defaultValue={null}
                  rules={{ required: true }}
                  render={({ field }) =>
                    <SPUDAutoComplete
                      label='Template'
                      selectedOption={field.value}
                      isMulti={false}
                      disabled={false}
                      options={templates}
                      onChange={(option) => {
                        field.onChange(
                          templates.find((templateType: {id: number, name: string}) => templateType.id === option))
                      }}
                    />
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4 }}>
                <Controller
                  name='email_summary'
                  control={methods.control}
                  defaultValue={false}
                  rules={{ required: false }}
                  render={({ field }) =>
                    <SPUDToggle
                      padding='0'
                      label='Include summary'
                      checked={methods.getValues?.(field.name)}
                      handleChange={(checked: boolean) =>
                        methods.setValue?.(field.name, checked)
                      }
                      {...field}
                    />
                  }
                />
                <FieldDesc>Include summary of records in an email to the administrator.</FieldDesc>
              </Col>
            </Row>
            <Row>
              <Col>
                <Controller
                  name='email_content'
                  control={methods.control}
                  defaultValue={null}
                  rules={{ required: false }}
                  render={({ field }) =>
                    <SPUDTextArea
                      field={field as ControllerRenderProps | null | undefined | {
                          value: string,
                          name: string,
                          onChange: (value: React.ChangeEvent<HTMLInputElement>) => void,
                        }}
                      label="Email content"
                      highlight={false}
                      canShowLineBreaks={false}
                      disabled={false}
                      minHeight='150px'
                    />
                  }
                />
                <FieldDesc>This is to add additional text to the email.</FieldDesc>
              </Col>
            </Row>
          </Col>
        </Row>

        <StyledButton
          aria-label='Create Campaign'
          active={methods.formState.isValid}
          disabled={!methods.formState.isValid}
          type='submit'
          className={methods.formState.isValid ? '' : 'disabled-button'}
        >
          Create campaign
        </StyledButton>
      </FormContainer>
    </form>
  )
}

export default EmailCampaignForm
