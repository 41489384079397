import React, { useEffect, useState } from 'react'
import { Title, Row, Col } from '@ix/ix-ui'
import styled from 'styled-components'
import { SPUDRecord } from '../../../@types/SPUDRecord.type'
import { SPUDSiteRecordDetails } from '../../../@types/Site.type'
import BulkAutoUpdateForm from './BulkAutoUpdateForm'
import { BulkAutoUpdateRecord, BulkUpdateContextType, BulkUpdateSPUDRecordType } from './BulkUpdateContext.type'
import { BulkUpdateFlowStateType } from './BulkAutoUpdateService'
import { SPUDInputField } from '../../helpers/record'
import { SPUDOrganisationRecordDetails } from '../../../@types/Organisation.type'

const SPUDSiteForm = styled.div`
  width: 70%;
`

const FormContainer = styled.div`
  width: 100%;
  margin-left: 0;
  padding: 1em;
  background-color: #fff;
`

type SiteFormProps = {
  recordId: number,
  setBulkAutoUpdateFlowState: (flowState: BulkUpdateFlowStateType) => void
} & BulkUpdateContextType

function SiteForm (
  {
    recordId,
    organisation,
    saveSiteData,
    setBulkAutoUpdateFlowState,
    site,
    activeServiceIndexContext,
    saveOrganisationData,
    setDialogOptions,
  }: SiteFormProps) {
  const [siteData, setSiteData] = useState<BulkAutoUpdateRecord<SPUDSiteRecordDetails>>()

  const updateOrganisation = (key: keyof SPUDOrganisationRecordDetails, value: unknown) => {
    let currentData = organisation.currentData
    currentData = {
      ...currentData,
      update: {
        ...organisation.currentData.update,
        data: {
          ...organisation.currentData.update.data,
          [key]: value,
        },
      },
    } as SPUDRecord<SPUDOrganisationRecordDetails>
    saveOrganisationData({
      recordType: 'organisation',
      currentData: currentData,
      originalData: organisation.originalData,
      comment: '',
      confirmed: true,
      noChanges: false,
    })
  }

  useEffect(() => {
    setSiteData(site)
  }, [])

  const ressetOrganisation = () => {
    saveOrganisationData({
      recordType: 'organisation',
      currentData: organisation.originalData,
      originalData: organisation.originalData,
      comment: '',
      confirmed: true,
      noChanges: false,
    })
  }

  return <SPUDSiteForm>
    <Row style={{ padding: '1em' }}>
      <Col>
        <Title>
          Update site {siteData?.originalData.update?.data?.name
            ? ` - ${siteData?.originalData.update?.data?.name}`
            : ''
          }
        </Title>
      </Col>
    </Row>
    <Row style={{ padding: '1em' }}>
      <Col>
        <hr style={{ width: '100%' }}/>
      </Col>
    </Row>
    <Row>
      {organisation && <Col style={{ padding: 10 }}>
        <Title level={3}>
          Organisation
        </Title>
        <div>
          <Title level={4}>
            <SPUDInputField
              label='Organisation name'
              value={organisation.currentData.update.data?.name}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                updateOrganisation('name', event.target.value)
              }}
              fullwidth
            />
          </Title>
        </div>
        <div>
          <Title level={4}>
            <SPUDInputField
              label='Organisation ABN'
              value={organisation.currentData.update.data.abn}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                updateOrganisation('abn', event.target.value)
              }}
              fullwidth
            />
          </Title>
        </div>
      </Col>}
    </Row>
    <Row>
      <Col>
        {siteData && <FormContainer>
          <BulkAutoUpdateForm
            recordId={recordId.toString()}
            recordType='site'
            formData={siteData.currentData.update?.data as unknown as BulkUpdateSPUDRecordType<SPUDSiteRecordDetails>}
            originalData={siteData.originalData.update?.data}
            contextUpdater={(data, comment, confirm, noChanges) => {
              let currentData = siteData.currentData
              currentData = {
                ...currentData,
                update: {
                  ...siteData.currentData.update,
                  data: {
                    ...siteData.currentData.update.data,
                    ...data,
                  },
                },
              } as BulkUpdateSPUDRecordType<SPUDSiteRecordDetails>
              saveSiteData({
                recordType: 'site',
                currentData: currentData,
                originalData: siteData.originalData,
                comment: comment,
                confirmed: confirm,
                noChanges: noChanges,
              })
            }}
            onNext={() => {
              setBulkAutoUpdateFlowState('service')
            }}
            confirmed={site.confirmed}
            noChanges={site.noChanges}
            comment={site.comment}
            activeServiceIndexContext={activeServiceIndexContext}
            setDialogOptions={setDialogOptions}
            isNewService={false}
            organisationData={organisation}
            ressetOrganisation={ressetOrganisation}
          />
        </FormContainer>}
      </Col>
    </Row>
  </SPUDSiteForm>
}

export default SiteForm
