import React from 'react'
import { Col, Row, Title } from '@ix/ix-ui'
import styled from 'styled-components'
import { SocialType } from './SPUDSocials'
import { SPUDEmailType } from './SPUDEmails'
import { ContactType } from './SPUDContact'

type SPUDMultiEntryFieldSiteDetailsProps = {
  fieldType: string,
  items: Array<SocialType | SPUDEmailType | ContactType>,
  dataStructure: {
    [key: string]: {
      dataType: 'string' | 'boolean',
      primary: boolean,
      humanReadableLabel: string,
    },
  },
}

export const SiteDetailsChipSummaryChip = styled.div`
  border-radius: 3px;
  border: 1px solid #3a8ae8;
  padding: 5px;
  color: #4c1811;
  font-size: 14px;
  margin: 0.3em 0;
  background-color: #fff;
  justify-content: flex-start;
  display: flex;
  width: 100%;
  position: relative;
`

function SPUDMultiEntryFieldSiteDetails ({ items, fieldType, dataStructure }: SPUDMultiEntryFieldSiteDetailsProps) {
  const generateRow = (item: SocialType | SPUDEmailType | ContactType, index: number) => {
    const cellArray: Array<React.ReactNode | string> = []
    Object.keys(dataStructure).forEach((key: string) => {
      const value = item[key as keyof SocialType & keyof SPUDEmailType & keyof ContactType]
      const isPrimary = dataStructure[key].primary
      const humanReadableLabel = dataStructure[key].humanReadableLabel
      if (isPrimary) {
        cellArray.push(<div><strong>Site {humanReadableLabel} {index}: </strong>{value}{' '}</div>)
      } else if (value) {
        cellArray.push(` | ${humanReadableLabel} - ${value} `)
      }
    },
    )
    return (
      <SiteDetailsChipSummaryChip>
        {cellArray.map((cell) => (cell))}
      </SiteDetailsChipSummaryChip>
    )
  }

  return (
    <Row>
      <Col><Title level={4}>Site {fieldType} Details</Title></Col>
      {items.map((item, index) => (
        <Row key={index}>
          <Col>
            {generateRow(item as SocialType | SPUDEmailType | ContactType, index + 1)}
          </Col>
        </Row>
      ))}
    </Row>
  )
}

export default SPUDMultiEntryFieldSiteDetails
