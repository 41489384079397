import React, { useState } from 'react'
import {
  Title,
  Row,
  Col,
  Toggle, Alert,
} from '@ix/ix-ui'
import StreetTypes from '../../helpers/supplimentaryFiles/streetTypes.json'
import SPUDAutoComplete from './SPUDAutoComplete'
import { SPUDInputField } from '../../helpers/record'
import SPUDLocationAutocomplete, { LocationOptionType } from './SPUDLocationAutocomplete'
import { Controller, useFormContext } from 'react-hook-form'
import { SingleValue } from 'react-select'
import SPUDGeocode from './SPUDGeocode'
import { Option } from '../forms/AddEditRecord.type'
import styled from 'styled-components'
import SPUDAddressAutocomplete from './SPUDAddressAutocomplete'
import { withContext } from '../../context/AppContext'
import { fetchGeocodeFromAddress, getPlaceDetails, PlaceResult } from '../../services/googleAPI.service'
import { processGoogleAddressComponents } from '../../services/location.service'
import { BulkServiceProviderUpdateType } from '../../services/spud.service'
import BulkUpdateRecordChangeReview from '../forms/BulkUpdateRecordChangeReview'

export type SPUDLocationProps = {
  title: string,
  formKey: string,
  value: Location,
  callback: (name: string, params: Record<string, unknown>) => void,
  draftValidation: boolean
  highlight: boolean,
  disabled: boolean,
  externalForm: boolean,
  getPlaceDetails?: typeof getPlaceDetails
  bulkUpdateChanges: BulkServiceProviderUpdateType
}

/* eslint-disable camelcase */
export interface Location {
  confidential: false,
  flat_unit: null | string,
  floor_level: null | string,
  building_name: string | null,
  street_name: string | null,
  street_number: null | number,
  street_type: string | null,
  street_suffix: string | null,
  suburb: string | null,
  state: string | null,
  postcode: string | null | undefined,
  geo_point: {
    lat: string,
    lon: string
  }
}

const OrSeparator = styled.div`
  display: flex;
  margin: 1em 0;
  hr {
    width: 100%;
    border: 1px solid #8c9bad;
    height: 1px;
  }
  div {
    margin: 0 1em;
    color: #8c9bad;
    width: 22em;
    text-align: center;
  }
`

function SPUDLocation (
  {
    title,
    formKey,
    draftValidation,
    highlight,
    getPlaceDetails,
    disabled,
    externalForm,
    bulkUpdateChanges,
  }: SPUDLocationProps): React.ReactElement {
  const { control, getValues, setValue, formState: { errors } } = useFormContext()
  const [oldLocation, setOldLocation] = useState<Location>({
    building_name: '',
    confidential: false,
    flat_unit: '',
    floor_level: '',
    geo_point: { lat: '', lon: '' },
    postcode: '',
    state: '',
    street_name: '',
    street_number: 0,
    street_suffix: '',
    street_type: '',
    suburb: '',
  })
  const [locationValueChanged, setLocationValueChanged] = useState(false)

  const formErrors = errors as {
    location?: {
      [x: string]: {
        type: unknown
      }
    }
  }

  const mapValueToReactSelectOption = (
    selectedValue: string | null | { name: string },
  ): Option => {
    if (typeof selectedValue !== 'string' && selectedValue) {
      return { id: selectedValue.name || '', name: selectedValue.name || '' }
    }
    return { id: selectedValue || '', name: selectedValue || '' }
  }

  const renderError = (errorLabel: string, locationField: string) => {
    if (formErrors?.location?.[locationField]) {
      return <span>
        <Alert type="error">
          {errorLabel} is {
            formErrors?.location?.[locationField]?.type === 'validate'
              ? 'required'
              : formErrors?.location?.[locationField]?.type
          }
        </Alert>
      </span>
    }
  }

  const automaticallySetGeoCode = async (address: Location) => {
    const formattedAddress = `${address?.building_name || ''} ` +
    `${address?.floor_level || ''} ` +
    `${address?.street_suffix || ''} ` +
    `${address?.street_number || ''} ` +
    `${address?.street_name || ''} ` +
    `${address?.street_type || ''} ` +
    `${address?.suburb || ''} ` +
    `${address?.state || ''} ` +
    `${address?.postcode || ''} `
    const latlng = await fetchGeocodeFromAddress(formattedAddress)
    setValue('location', { ...address, geo_point: { lat: latlng.lat, lon: latlng.lon } })
  }

  const acceptChanges = (key: string, value: unknown, newChanges?: boolean) => {
    if (newChanges) {
      setOldLocation(getValues(key) as Location)
      setValue(key, value)
      automaticallySetGeoCode(value as Location)
    } else {
      setValue(key, oldLocation)
    }
  }

  return (
    <div>
      <Row>
        <Col direction='row' align='baseline'>
          <Title>{title}</Title>
          <Controller
            name='location.confidential'
            control={control}
            rules={{ required: false }}
            defaultValue={false}
            render={() =>
              <Toggle
                label='Confidential'
                offColor="#E3EDFA"
                checked={getValues('location.confidential')} handleChange={(checked: boolean) =>
                  setValue('location.confidential', checked)
                }
                onKeyDown={(key: React.KeyboardEvent<HTMLInputElement>) => {
                  const checked = getValues('location.confidential')
                  if (key.key === 'Enter') {
                    setValue?.('location.confidential', !checked)
                  }
                }}
                disabled={disabled}
              />
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <SPUDAddressAutocomplete label="Search for Location"
            onChange={(value, actionMeta) => {
              if (actionMeta.action === 'select-option') {
                setValue('location.geo_point', {})
              }
              if (value?.id && typeof value?.id === 'string') {
                const detailsService = getPlaceDetails?.()
                detailsService?.getDetails(
                  { placeId: value.id },
                  (response: PlaceResult) => {
                    if (response?.address_components) {
                      setValue('location', {
                        ...getValues('location'),
                        ...processGoogleAddressComponents(response),
                      })
                    }
                  })
              }
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <OrSeparator >
            <hr/>
            <div>Or enter manually</div>
            <hr/>
          </OrSeparator>
        </Col>
      </Row>
      <Row>
        <Col style={{ marginTop: '15px' }}>
          <Controller
            name='location.flat_unit'
            control={control}
            rules={{ required: false }}
            defaultValue={null}
            render={({ field }) =>
              <SPUDInputField
                id='flatUnitNumber'
                placeholder="Flat/Unit Number"
                type='text'
                label="Flat/Unit Number"
                fullwidth
                value={getValues('location.flat_unit')}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setLocationValueChanged(true)
                  field.onChange(event)
                }}
                name={field.name}
                ref={field.ref}
                onBlur={field.onBlur}
              />
            }
          />
        </Col>
        <Col style={{ marginTop: '15px' }}>
          <Controller
            name='location.floor_level'
            control={control}
            rules={{ required: false }}
            defaultValue={null}
            render={({ field }) =>
              <SPUDInputField
                id='floorLevel'
                placeholder="Floor Level"
                type='text'
                label="Floor Level"
                fullwidth
                value={getValues('location.floor_level')}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setLocationValueChanged(true)
                  field.onChange(event)
                }}
                name={field.name}
                ref={field.ref}
                onBlur={field.onBlur}
              />
            }
          />
        </Col>
      </Row>
      <Row>
        <Col style={{ marginTop: '15px' }}>
          <Controller
            name='location.building_name'
            control={control}
            rules={{ required: false }}
            defaultValue={null}
            render={({ field }) =>
              <SPUDInputField
                id='buildingName'
                placeholder="Building Name"
                label="Building Name"
                fullwidth
                value={getValues('location.building_name')}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setLocationValueChanged(true)
                  field.onChange(event)
                }}
                name={field.name}
                ref={field.ref}
                onBlur={field.onBlur}
              />
            }
          />
        </Col>
        <Col style={{ marginTop: '15px' }}>
          <Controller
            name='location.street_number'
            control={control}
            rules={{ required: !draftValidation }}
            defaultValue={null}
            render={({ field }) =>
              <SPUDInputField
                id='streetNumber'
                placeholder="Street Number"
                label="Street Number"
                fullwidth
                required={true}
                hideAsterisk={true}
                highlight={!getValues('location.street_number')}
                value={getValues('location.street_number')}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setLocationValueChanged(true)
                  field.onChange(event)
                }}
                name={field.name}
                ref={field.ref}
                onBlur={field.onBlur}
              />
            }
          />
          {renderError('Street number', 'street_number')}
        </Col>
      </Row>
      <Row>
        <Col style={{ marginTop: '15px' }}>
          <Controller
            name='location.street_name'
            control={control}
            rules={{ required: !draftValidation }}
            defaultValue={null}
            render={({ field }) =>
              <SPUDInputField
                id='streetName'
                placeholder="Street Name"
                required={true}
                hideAsterisk={true}
                highlight={!getValues('location.street_name')}
                label="Street Name"
                fullwidth
                value={getValues('location.street_name')}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setLocationValueChanged(true)
                  field.onChange(event)
                }}
                name={field.name}
                ref={field.ref}
                onBlur={field.onBlur}
              />
            }
          />
          {renderError('Street name', 'street_name')}
        </Col>
        <Col>
          <Controller
            name='location.street_type'
            control={control}
            rules={{ required: !draftValidation }}
            defaultValue={null}
            render={({ field }) =>
              <SPUDAutoComplete
                recordType={formKey}
                highlight={!getValues('location.street_type')}
                label="Street Type"
                options={StreetTypes.streetTypes}
                isMulti={false}
                selectedOption={getValues('location.street_type')}
                disabled={disabled}
                onChange={(value) => {
                  setLocationValueChanged(true)
                  field.onChange(value)
                }}
                filterConfig={{
                  ignoreCase: true,
                  matchFrom: 'start',
                }}
              />
            }
          />
          {renderError('Street Type', 'street_type')}
        </Col>
        <Col>
          <Controller
            name='location.street_suffix'
            control={control}
            rules={{ required: false }}
            defaultValue={null}
            render={({ field }) =>
              <SPUDAutoComplete
                recordType={formKey}
                label="Street Suffix"
                options={[
                  { id: 'Central', name: 'Central' },
                  { id: 'East', name: 'East' },
                  { id: 'North', name: 'North' },
                  { id: 'South', name: 'South' },
                  { id: 'West', name: 'West' },
                ]}
                isMulti={false}
                selectedOption={getValues('location.street_suffix')}
                disabled={disabled}
                onChange={(value) => {
                  setLocationValueChanged(true)
                  field.onChange(value)
                }}
              />
            }
          />
          {renderError('Street Suffix', 'street_suffix')}
        </Col>
      </Row>
      <Row>
        <Col>
          <Controller
            name='location.suburb'
            control={control}
            rules={{ required: !draftValidation }}
            defaultValue={null}
            render={() =>
              <SPUDLocationAutocomplete
                placeholder="Suburb"
                value={mapValueToReactSelectOption(getValues('location.suburb'))}
                label='Suburb'
                highlight={highlight}
                onChange={(value: SingleValue<LocationOptionType>) => {
                  if (value) {
                    setValue('location.suburb', value.name.toUpperCase())
                    setValue('location.state', value.state)
                    setValue('location.postcode', value.postcode)
                    setLocationValueChanged(true)
                  }
                }}
                required
                disabled={disabled}
                multi={false}
              />
            }
          />
          {renderError('Suburb', 'suburb')}
        </Col>
        <Col style={{ marginTop: '15px' }}>
          <Controller
            name='location.state'
            control={control}
            defaultValue={null}
            rules={{ required: true }}
            render={({ field }) =>
              <SPUDInputField
                id='state'
                placeholder="State"
                label="State"
                fullwidth
                required={true}
                hideAsterisk={true}
                highlight={!getValues('location.state')}
                value={getValues('location.state')}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setLocationValueChanged(true)
                  field.onChange(event)
                }}
                name={field.name}
                ref={field.ref}
                onBlur={field.onBlur}
              />
            }
          />
          {renderError('State', 'state')}
        </Col>
        <Col style={{ marginTop: '15px' }}>
          <Controller
            name='location.postcode'
            control={control}
            rules={{ required: !draftValidation }}
            defaultValue={null}
            render={({ field }) =>
              <SPUDInputField
                id='postcode'
                placeholder="Postcode"
                label="Postcode"
                fullwidth
                required={true}
                hideAsterisk={true}
                highlight={!getValues('location.postcode')}
                value={getValues('location.postcode')}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setLocationValueChanged(true)
                  field.onChange(event)
                }}
                name={field.name}
                ref={field.ref}
                onBlur={field.onBlur}
              />
            }
          />
          {renderError('Postcode', 'postcode')}
        </Col>
      </Row>
      {!externalForm && <Row>
        <Col>
          <SPUDGeocode
            disabled={disabled}
            address={getValues('location')}
            callback={(latLong) => {
              setLocationValueChanged(false)
              setValue('location.geo_point', latLong)
            }}
            draftValidation={draftValidation}
            locationValueChanged={locationValueChanged}
            setLocationValueChanged={setLocationValueChanged}
          />
          {renderError('Geocode', 'geo_point')}
        </Col>
      </Row>}
      {bulkUpdateChanges && <Row>
        <Col>
          <BulkUpdateRecordChangeReview
            bulkUpdateChanges={bulkUpdateChanges}
            setValue={acceptChanges}
            fieldKey='location'
            fieldLabel={title}
            currentFieldValue={getValues('location')}
          />
        </Col>
      </Row>}
    </div>
  )
}

export default withContext(SPUDLocation)
